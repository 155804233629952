<template>
    <div>
        <el-select class="select-default float-sm-right"
                   v-model="timefilter">
            <el-option class="select-default"
                       v-for="item in timeSelectors"
                       :key="item.key"
                       :label="item.label"
                       :value="item.key">
            </el-option>
        </el-select>
        <line-chart :labels="chartXAxisLabel" ref="lineChart"
                    :datasets="chartDataSets"
                    :height="100"
                    :extraOptions="extraOptions">
        </line-chart>
    </div>
</template>

<script>
    import LineChart from 'src/components/UIComponents/Charts/LineChart'
    import { Select, Option} from 'element-ui'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'date-distribution-chart',
        props: {
            chartData: Array,
            lastDate : Date,
        },
        components: {
            LineChart,
            [Select.name]: Select,
            [Option.name]: Option,
        },
        data() {
            return {
                extraOptions: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        //display: true,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0
                            }
                        }]
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                    },
                },
                timefilter: 'last3m',

            }
        },
        computed: {
            timeSelectors() {
                return [
                    { label: this.$t('charts.dateDistChart.filter.last1m'), key: "last1m", days: 30 },
                    { label: this.$t('charts.dateDistChart.filter.last3m'), key: "last3m", days: 91 },
                    { label: this.$t('charts.dateDistChart.filter.last6m'), key: "last6m", days: 183 },
                    { label: this.$t('charts.dateDistChart.filter.last1y'), key: "last1y", days: 365 },
                ];
            },
            filteredData() {
                if (this.chartData == undefined || this.chartData.length == 0) return [];

                let lastDate = new Date(Date.now());
                if (this.lastDate != null && this.lastDate != undefined)
                    lastDate = this.lastDate;

                let tFilter = this.timeSelectors.find(c => c.key == this.timefilter);
                let dateBP = new Date(lastDate);
                dateBP = new Date(dateBP.setDate(dateBP.getDate() - tFilter.days));

                //return this.chartData.filter(a => {
                //    let dt = new Date(a.timeStampUtc);
                //    let res = dateBP < dt && dt <= lastDate;
                //    //console.log(dt, res);
                //    return res;
                //});

                let filteredData = this.chartData.filter(a => {
                    let dt = new Date(a.timeStampUtc);
                    let res = dateBP < dt && dt <= lastDate;
                    //console.log(dt, res);
                    return res;
                });


                return filteredData.sort(function (a, b) { return sortingHandler(a, b, { order: 'ascending', name: 'timeStampUtc' }); })

            },
            chartXAxisLabel() {
                return this.filteredData.map(a => this.$moment(a.timeStampUtc).format("DD-MM-YYYY"));
            },
            chartInitiatedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countInitiated;
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.dateDistChart.initiatedLabel'),
                    fill: false,
                    backgroundColor: '#6bd098',
                    borderColor: '#6bd098',
                    data: data,
                };
            },
            chartInteractedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countInteracted;
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.dateDistChart.interactedLabel'),
                    fill: false,
                    backgroundColor: '#fbc658',
                    borderColor: '#fbc658',
                    data: data,
                };
            },
            chartReactedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countReacted;
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.dateDistChart.reactedLabel'),
                    fill: false,
                    backgroundColor: '#ffa500',
                    borderColor: '#ffa500',
                    data: data,
                };
            },
            chartCompromisedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countCompromised;
                        return 0;
                    });
                }

                return {
                    label: this.$t('charts.dateDistChart.compromisedLabel'),
                    fill: false,
                    backgroundColor: '#ef8157',
                    borderColor: '#ef8157',
                    data: data,
                };
            },
            //chartReInteractedData() {
            //    let data = null;
            //    if (this.filteredData != undefined && this.filteredData.length > 0) {
            //        data = this.filteredData.map(a => {
            //            if (new Date(a.timeStampUtc) <= new Date(Date.now()))
            //                return a.countReInteracted;
            //            return 0;
            //        });
            //    }

            //    return {
            //        label: 'ReInteracted',
            //        fill: false,
            //        backgroundColor: '#fdeece',
            //        borderColor: '#fdeece',
            //        data: data,
            //    };
            //},
            //chartReReactedData() {
            //    let data = null;
            //    if (this.filteredData != undefined && this.filteredData.length > 0) {
            //        data = this.filteredData.map(a => {
            //            if (new Date(a.timeStampUtc) <= new Date(Date.now()))
            //                return a.countReReacted;
            //            return 0;
            //        });
            //    }
            //    return {
            //        label: 'ReReacted',
            //        fill: false,
            //        backgroundColor: '#ffe4b5',
            //        borderColor: '#ffe4b5',
            //        data: data,
            //    };
            //},
            //chartReCompromisedData() {
            //    let data = null;
            //    if (this.filteredData != undefined && this.filteredData.length > 0) {
            //        data = this.filteredData.map(a => {
            //            if (new Date(a.timeStampUtc) <= new Date(Date.now()))
            //                return a.countReCompromised;
            //            return 0;
            //        });
            //    }
            //    return {
            //        label: 'ReCompromised',
            //        fill: false,
            //        backgroundColor: '#fadace',
            //        borderColor: '#fadace',
            //        data: data,
            //    };
            //},
            chartTrainedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countTrained;
                        return 0;
                    });
                }
                return {
                    label: this.$t('charts.dateDistChart.trainedLabel'),
                    fill: false,
                    backgroundColor: '#51bcda',
                    borderColor: '#51bcda',
                    data: data,
                };
            },
            chartReportedData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.countReported;
                        return 0;
                    });
                }
                return {
                    label: this.$t('charts.dateDistChart.reportedLabel'),
                    fill: false,
                    backgroundColor: '#e2f6eb',
                    borderColor: '#e2f6eb',
                    data: data,
                };
            },
            chartDataSets() {
                return [
                    this.chartInitiatedData,
                    this.chartInteractedData,
                    this.chartReactedData,
                    this.chartCompromisedData,
                    this.chartTrainedData,
                    this.chartReportedData
                ];
            }
        }
    }
</script>

<style scoped>
</style>