<template>
    <div class="row entity-customization">
        <div class="col-12 col-md-6">
            <card>
                <h4 slot="header" class="card-title">{{$t('setup.customization.info.header')}}</h4>
                <div class="text-muted">
                    <p>
                        {{$t('setup.customization.info.text1')}}
                        <br /><br />
                        {{$t('setup.customization.info.text2')}}
                    </p>

                    <p>
                        {{$t('setup.customization.info.text3')}}
                    </p>
                    <ul>
                        <li>
                            {{$t('setup.customization.info.point1')}}
                        </li>
                        <li>
                            {{$t('setup.customization.info.point2')}}
                        </li>
                        <li>
                            {{$t('setup.customization.info.point3')}}
                        </li>
                        <li>
                            {{$t('setup.customization.info.point4')}}
                        </li>
                        <li>
                            {{$t('setup.customization.info.point5')}}
                        </li>
                    </ul>
                </div>
            </card>
        </div>
        <div class="col-12 col-md-6">
            <card>
                <h4 slot="header" class="card-title">{{$t('setup.customization.customValues.header')}}</h4>
                <form>
                    <div class="row">
                        <div class="col-12">
                            <fg-input type="text" :label="$t('setup.customization.customValues.form.name.label')" :placeholder="$t('setup.customization.customValues.form.name.placeholder')" v-model="cbCustomData.name" name="name" data-v-step="step-cust-name"
                                        v-validate="modelValidations.name"
                                        :error="getError('name')"></fg-input>
                        </div>
                        <div class="col-12 col-xl-7">
                            <fg-input type="text" :label="$t('setup.customization.customValues.form.abbreviation.label')" :placeholder="$t('setup.customization.customValues.form.abbreviation.placeholder')" v-model="cbCustomData.abbreviation" name="abbreviation" data-v-step="step-cust-abbre"
                                        v-validate="modelValidations.abbreviation"
                                        :error="getError('abbreviation')"></fg-input>
                        </div>
                        <div class="col-12 col-xl-5">
                            <div class="form-group">
                                <label>
                                    {{$t('setup.customization.customValues.form.color.label')}}
                                </label>
                                <div class="color-picker" data-v-step="step-cust-color">
                                    <el-color-picker v-model="cbCustomData.color"></el-color-picker>
                                    <h6 class="d-inline" style="margin-left: 0.5rem; top: -10px; position: relative;">{{cbCustomData.color}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" data-v-step="step-cust-logo">
                            <div class="text-center">
                                <img :src="logoPreview" alt="your logo" class="logo-preview img-fluid" />
                            </div>
                            <div class="text-center">
                                <p-button @click="uploadFile" type="warning">
                                    {{$t('setup.customization.customValues.btnSelectLogo')}}
                                </p-button>
                                <input type="file" @change="handleFileChange" style="display: none;" ref="fileinput" accept="image/*" />
                                <p-button @click="setDefaultLogo" type="info">
                                    {{$t('setup.customization.customValues.btnDefaultLogo')}}
                                </p-button>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <p-button @click="validate" type="success" data-v-step="step-cust-sbtn">
                                {{$t('setup.customization.customValues.btnSave')}}
                            </p-button>
                        </div>
                    </div>
                </form>
            </card>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Upload, ColorPicker } from 'element-ui'
    import { Card, } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    export default {
        name: 'customization',
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Upload.name]: Upload,
            [ColorPicker.name]: ColorPicker,
        },
        props: {
            entityHID: {
                type: String,
                required: true
            }
        },
        data() {
            let vueThis = this;
            return {
                cbCustomData: {
                    color: null,
                    name: null,
                    logo: null,
                    abbreviation: null,
                    logoUrl: null,
                },
                modelValidations: {
                    name: {
                        required: true,
                        max: vueThis.$root.config.maxCustomNameLength,
                    },
                    abbreviation: {
                        required: true,
                        regex: /^[a-z0-9]+$/i,
                        max: vueThis.$root.config.maxCustomAbbreviationLength,
                    },
                },
            }
        },
        methods: {
            initComponent: function () {
                this.loadCustomData();
            },
            loadCustomData() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.customization.getCustomDataUrl(this.entityHID), null)
                    .then(response => {
                        this.cbCustomData = response.data[0];
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            handleFileChange(e) {
                let logoFile = e.target.files[0];
                //this.$refs.logoPreview.src = URL.createObjectURL(this.cbCustomData.logo);
                console.log(logoFile);
                if (logoFile.size > 1 * 1000 * 1000) {
                    swal.fire({
                        icon: 'error',
                        title: this.$t('setup.customization.customValues.uploadFile.errMaxSize'),
                        showConfirmButton: true,
                        timer: this.$root.config.alertTimer15,
                        timerProgressBar: true,
                    });
                    return;
                }


                this.cbCustomData.logo = logoFile;
            },
            uploadFile() {
                this.$refs.fileinput.value = "";
                this.$refs.fileinput.click();
            },
            setDefaultLogo() {
                this.cbCustomData.logo = null;
                this.cbCustomData.logoUrl = this.$root.config.defaultCustomLogoUrl;
            },
            saveCustomData() {
                let vueThis = this;

                let formData = new FormData();
                formData.append("name", this.cbCustomData.name);
                formData.append("abbreviation", this.cbCustomData.abbreviation);
                formData.append("color", this.cbCustomData.color);
                formData.append("logo", this.cbCustomData.logo);
                formData.append("logoUrl", this.cbCustomData.logoUrl);


                this.axios.post(this.$root.config.entity.customization.updateCustomDataUrl(this.entityHID), formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(response => {
                        //console.log(response.data);
                        this.$emit('accountDetailUpdated', response.data);
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('setup.customization.customValues.updateCustomValues.success'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('setup.customization.customValues.updateCustomValues.failed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.saveCustomData();
                    }
                })
            },
            getError(fieldName) {
                let familierName = this.$t('setup.customization.customValues.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;

                if (fieldName == "abbreviation" && this.errors.items.some(c => c.field === "abbreviation" && c.rule === "regex")) {
                    errMsg = this.$t('setup.customization.customValues.form.abbreviation.errValidChars');
                }
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
        },
        computed: {
            logoPreview() {
                if (this.cbCustomData.logo != null) {
                    return URL.createObjectURL(this.cbCustomData.logo);
                }
                return this.cbCustomData.logoUrl;
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }

</script>

<style scoped>
</style>