<template>
    <card class="cb-card">
        <template slot="leftside">
            <div class="selectable-container h-100" v-if="enableSelect">
                <el-tooltip :content="$t('cards.campaignBlockCard.actions.btnRemove')" :open-delay="300" placement="right" v-if="currentCB.selected">
                    <p-button type="danger" icon @click="selectCBG(false)">
                        <i class="fa fa-times"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('cards.campaignBlockCard.actions.btnAdd')" :open-delay="300" placement="right" v-else>
                    <p-button type="warning" icon @click="selectCBG(true)" :disabled="disableAddBtn">
                        <i class="fa fa-add"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </template>

        <campaign-block-detail :campaignBlock="currentCB" :diffs="diffs" :CCs="CCs" :entityHID="entityHID"
                                @updateCC="updateGroupCampBlockByCC" @updateDiff="updateGroupCampBlockByDiff"
                                ref="cbd">

        </campaign-block-detail>
    </card>      
</template>

<script>
    import CampaignBlockDetail from 'src/components/UIComponents/CampaignBlock/CampaignBlockDetail.vue'

    export default {
        name: 'campaign-block-card',
        components: {
            CampaignBlockDetail,
        },
        props: {
            campaignBlocksForGoup: {
                type: Array,
                required: true
            },
            group: {
                type: String,
                required: true
            },
            enableSelect: {
                type: Boolean,
                default: false
            },
            entityHID: {
                type: String,
                required: true
            },
            disableAddBtn: {
                type: Boolean,
                default: false
            },
            preSelectedCC: {
                type: String,
            },
            preSelectedDiff: {
                type: Number,
            },
        },
        data() {
            return {
                trigger: 0,
                selCC: undefined,
                selDiff: undefined,
            }
        },
        methods: {
            refresh() {
                this.$refs.cbd.refresh();
                this.trigger++;
            },
            selectCBG(value) {
                this.$emit('selectedChanged', this.currentCB, value);
                //this.trigger++;
            },
            getCampaignBlockByGrouped(cbs) {
                let item = cbs.find((item) => item.group === this.group && item.cultureCode === this.selectedCC && item.difficulty == this.selectedDiff);
                if (item == undefined) {
                    //console.log("update", groupedCB, item);
                    item = cbs.find((item) => item.group === this.group && item.cultureCode === this.selectedCC);
                    if (item == undefined) {
                        //console.log("update default", groupedCB, item);
                        item = cbs[0];
                    }
                }
                return item;
            },
            getDiffCampBlockByGrouped(cbs) {
                return cbs.filter((item) => item.cultureCode === this.selectedCC).map((item) => item.difficulty).unique();
            },
            getCCsCampBlockByGrouped(cbs) {
                return cbs.map((item) => item.cultureCode).unique();
            },
            //----------
            updateGroupCampBlockByCC(cc) {
                this.selCC = cc;
            },
            updateGroupCampBlockByDiff(diff) {
                this.selDiff = diff;
            }
        },
        computed: {
            currentCB() {
                this.trigger;
                return this.getCampaignBlockByGrouped(this.campaignBlocksForGoup);
            },
            diffs() {
                return this.getDiffCampBlockByGrouped(this.campaignBlocksForGoup);
            },
            CCs() {
                return this.getCCsCampBlockByGrouped(this.campaignBlocksForGoup);
            },
            selectedCC() {
                if (this.selCC != undefined) {
                    return this.selCC;
                }
                if (this.preSelectedCC != undefined) {
                    return this.preSelectedCC;
                }

                return this.campaignBlocksForGoup[0].cultureCode;
            },
            selectedDiff() {
                if (this.selDiff != undefined) {
                    return this.selDiff;
                }
                if (this.preSelectedDiff != undefined) {
                    return this.preSelectedDiff;
                }

                return this.campaignBlocksForGoup[0].difficulty;
            }
        },

    }
</script>

<style scoped>
</style>