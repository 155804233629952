<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card" data-v-step="status-card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col d-flex justify-content-start">
                                <h4 class="card-title">{{$t('setup.whitelisting.header')}}</h4>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <p>
                            {{$t('setup.whitelisting.text')}}
                        </p>

                        <h6>{{$t('setup.whitelisting.listHeader')}}</h6>

                        <div class="ml-2 mt-3">
                            <p v-for="d in getDomains">
                                <span class="fa-solid fa-globe mr-2 text-muted"></span>
                                <b>{{d}}</b>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'whitelisting',
        computed: {
            getDomains() {
                return this.$root.config.emailDomains;
            }
        },
    }
</script>

<style scoped>
</style>