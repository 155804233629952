<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{$t('modalDialogs.domainBlacklist.header')}}</h4>

        <div ref="dblContent">
            <div>
                <fg-input class="input-sm"
                          :placeholder="$t('modalDialogs.domainBlacklist.searchPlaceholder')"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
            </div>
            <ul class="bl-list w-100">
                <li class="bl-item" v-for="item in queriedData" :key="item">
                    {{ item }}
                </li>
            </ul>
        </div>

        <div slot="footer" class="row mr-3">
            <div class="col">
                <p-button type="info" round @click="onClickCancel">
                    {{$t('modalDialogs.domainBlacklist.btnClose')}}
                </p-button>
            </div>
        </div>
    </modal>
</template>
<script>
    import { Modal } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a > b) {
            comparison = 1 * sign;
        } else if (a < b) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'domain-blacklist',
        components: {
            Modal,
        },
        props: {
            entityHID: String,
            show: Boolean,
        },
        data() {
            return {
                modalShow: false,
                dBlacklist: [],
                searchQuery: "",
                sortObject: { order: 'ascending' },
            }
        },
        methods: {
            loadBlacklist() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.dblContent));
                this.axios.post(this.$root.config.entity.domain.getDomainBlacklistUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        this.dBlacklist = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        loader.hide();
                        vueThis.genericErrorAlert();
                    });
            },
            onClickCancel: function () {
                this.$emit('hide', false);
            },
        },
        computed: {
            queriedData() {
                var cThis = this;
                if (!this.searchQuery) {
                    return this.dBlacklist.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); });
                }
                let result = this.dBlacklist
                    .filter((row) => {
                        let isIncluded = false;
                        let rowValue = row.toString();
                        if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                            isIncluded = true;
                        }                        
                        return isIncluded;
                    })
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); });
            }
        },
        watch: {
            show: function (value) {
                this.modalShow = value;
                this.searchQuery = "";
                this.dBlacklist = [];
            },
            modalShow: function (value) {
                if (value) {
                    this.loadBlacklist();
                }
                else {
                    this.$emit('hide', false);
                }
            },
        },
        created: function () {
        }
    }

</script>
<style scoped>
    .bl-list {
        min-height: 50px;
        max-height: 250px;
        overflow-x: auto;
    }

    .bl-list .bl-item {
        display: block;
        margin-top: 0.5rem;
    }
</style>
