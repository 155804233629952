<template>
    <div>
        <card>
            <div class="card-header">
                <slot name="cardheader"></slot>
            </div>
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12 col-md-6 pt-0 pt-md-2">
                        <dropdown-check :items="types" v-model="filter.type" :title="$t('campaignBlock.cbs.filter.type.placeholder')" class="mr-2"></dropdown-check>
                        <dropdown-check :items="themes" v-model="filter.themes" :title="$t('campaignBlock.cbs.filter.themes.placeholder')" class="mr-2"></dropdown-check>
                        <dropdown-check :items="languages" v-model="filter.languages" formatLabel="toUpper" :title="$t('campaignBlock.cbs.filter.languages.placeholder')" class="mr-2"></dropdown-check>
                        <dropdown-check :items="diffs" v-model="filter.diffs" :title="$t('campaignBlock.cbs.filter.diffs.placeholder')" class="mr-2"></dropdown-check>

                        <el-tooltip :content="$t('campaignBlock.cbs.filter.btnClearTooltip')" :open-delay="300" placement="top" v-if="filterDirty" class="text-muted py-0">
                            <el-button type="text" @click="clearFilter">
                                <i class="fa-solid fa-xmark mr-1"></i>
                                {{$t('campaignBlock.cbs.filter.btnClear')}}
                            </el-button>
                        </el-tooltip>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                        <div class="d-flex justify-content-end">
                            <fg-input class="input-sm mr-2"
                                      :placeholder="$t('campaignBlock.cbs.searchPlaceholder')"
                                      v-model="searchQuery"
                                      addon-right-icon="nc-icon nc-zoom-split">
                            </fg-input>
                        </div>
                    </div>
                </div>
            </div>
        </card>

        <!-- list CB -->
        <div class="row" v-infinite-scroll="loadMoreCBs" infinite-scroll-distance="100">
            <div class="col-12" v-for="(gcb, index) in queriedData">
                <campaign-block-card :entityHID="entityHID" ref="cbcs"
                                     :campaignBlocksForGoup="gcb.cbs" :group="gcb.group" :preSelectedCC="gcb.preSelectedCC" :preSelectedDiff="gcb.preSelectedDiff"
                                     :enableSelect="enableSelect" :disableAddBtn="disableAddBtn" 
                                     @selectedChanged="(gcb, value)=>$emit('selectedChanged', gcb, value)" ></campaign-block-card>
            </div>
        </div>

    </div>
</template>

<script>
    import { Card } from 'src/components/UIComponents'
    import { Select, Option, Button } from 'element-ui'
    import CampaignBlockCard from 'src/components/UIComponents/Cards/CampaignBlockCard.vue'
    import DropdownCheck from 'src/components/UIComponents/DropdownCheck.vue'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    function groupBy(arr, keyFunc) {
        return arr.reduce((result, item) => {
            const key = keyFunc(item);
            if (!result.includes(key)) {
                result.push(key);
            }
            return result;
        }, []);
    }

    export default {
        name: 'campaign-blocks',
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Button.name]: Button,
            Card,
            CampaignBlockCard,
            DropdownCheck,
        },
        props: {
            campaignBlocks: {
                type: Array,
                required: true
            },
            enableSelect: {
                type: Boolean,
                default: false
            },
            entityHID: {
                type: String,
                required: true
            },
            disableAddBtn:{
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                searchQuery: '',
                sortObject: { order: 'ascending', name: '' },
                filter: {},
                filterDirty: false,
                defaultFilter: {
                    type: ['Email', 'SMS'],
                    languages: [],
                    themes: [],
                    diffs: [],
                },
                //-------------------------

                //-------------------------
                visibleCBs: 5,
            }
        },
        methods: {
            clearFilter() {
                this.filter = JSON.parse(JSON.stringify(this.defaultFilter));
                this.filterDirty = false;
            }, 
            refresh() {
                this.$refs.cbcs.forEach((item) => item.refresh());
            },
            filterType(type) {
                this.filter.type = type;
            },
            //------------------
            groupCampaignBlocks(campBlocks) {
                let cbGrouped = Map.groupBy(campBlocks, ({ group }) => group);

                let result = [];

                cbGrouped.forEach((cbs, key) => {
                    let cb = cbs[0];

                    let item = {
                        cbs: cbs,
                        group: key,
                        selectedCC: cb.cultureCode,
                        selectedDiff: cb.difficulty,
                        updateProp: 0
                    }
                    result.push(item);
                });

                return result;
            },
            getCampaignBlockByGrouped(groupedCB) {
                let item = groupedCB.cbs.find((item) => item.group === groupedCB.group && item.cultureCode === groupedCB.selectedCC && item.difficulty == groupedCB.selectedDiff);
                if (item == undefined) {
                    //console.log("update", groupedCB, item);
                    item = groupedCB.cbs.find((item) => item.group === groupedCB.group && item.cultureCode === groupedCB.selectedCC);
                    if (item == undefined) {
                        //console.log("update default", groupedCB, item);
                        item = groupedCB.cbs[0];
                    }
                }
                return item;
            },
            getDiffCampBlockByGrouped(groupedCB) {
                return groupedCB.cbs.filter((item) => item.cultureCode === groupedCB.selectedCC).map((item) => item.difficulty).unique();
            },
            getCCsCampBlockByGrouped(groupedCB) {                
                return groupedCB.cbs.map((item) => item.cultureCode).unique();
            },
            //---------------
            loadMoreCBs() {
                this.visibleCBs += 5                
            },
        },
        computed: {
            languages() {
                if (this.campaignBlocks != undefined && this.campaignBlocks.length > 0) {
                    return groupBy(this.campaignBlocks, (cb) => cb.cultureCode);
                }
                return [];
            },
            themes() {
                if (this.campaignBlocks != undefined && this.campaignBlocks.length > 0) {
                    let result = [];
                    this.campaignBlocks.forEach(item => {
                        item.themes.forEach(t => {
                            if (!result.includes(t)) {
                                result.push(t);
                            }
                        });
                    });
                    return result;
                }
                return [];
            },
            types() {
                return [ 'Email', 'SMS'];
            },
            diffs() {
                return [1,2,3,4,5];
            },       
            //--------------------            
            queriedData() {
                this.trigger;

                let cThis = this;

                if (this.campaignBlocks == undefined)
                    return [];

                //----- filter                
                let resultCBs = this.campaignBlocks;
                //if (this.filter.type != "All") {
                resultCBs = resultCBs
                    .filter((cb) => {
                        if (this.filter.type.includes("SMS") && cb.channels.includes("sms")) return true;
                        if (this.filter.type.includes("Email") && cb.channels.includes("email")) return true;
                        return false;
                    });
                //}

                if (this.filter.themes.length > 0) {
                    resultCBs = resultCBs
                        .filter((cb) => {
                            return this.filter.themes.some((item) => cb.themes.includes(item));
                        });
                }
                //console.log("th", resultCBs);

                if (this.filter.languages.length > 0) {
                    resultCBs = resultCBs
                        .filter((cb) => {
                            return this.filter.languages.some((fc)=> fc === cb.cultureCode);
                        });
                }
                //console.log("cc", resultCBs);

                if (this.filter.diffs.length > 0) {
                    resultCBs = resultCBs
                        .filter((cb) => {
                            return this.filter.diffs.some((fd) => fd === cb.difficulty);
                        });
                }
                //console.log("d", resultCBs);

                //----- search
                if (this.searchQuery) {
                    let groups = resultCBs
                        .filter((cb) => {
                            let data = [cb.name, cb.description];
                            //console.log(data);
                            return data.some((d) => d.toLowerCase().includes(cThis.searchQuery.toLowerCase()))
                        }).map((cb) => cb.group);
                    //console.log(groups);
                    resultCBs = resultCBs
                        .filter((cb) => {
                            return groups.some((g) => g.toLowerCase() === cb.group.toLowerCase());
                        });
                }

                //---- grouped
                let result = this.groupCampaignBlocks(resultCBs);
                result.forEach((item) => {
                    item.preSelectedCC = this.filter.languages[0]
                    item.preSelectedDiff = this.filter.diffs[0]
                });

                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(0, this.visibleCBs);
            },

        },
        watch: {
            filter: {
                deep: true,
                handler(n) {
                    this.visibleCBs = 10; 
                    if (JSON.stringify(this.defaultFilter) != JSON.stringify(this.filter)) {
                        this.filterDirty = true;
                    }
                    else {
                        this.filterDirty = false;
                    }
                }
            },
            campaignBlocks: {
                deep: true,
                handler(n) {
                    this.visibleCBs = 10;
                }
            },
        },
        created(){
            this.filter = JSON.parse(JSON.stringify(this.defaultFilter));
        },
    }
</script>

<style scoped>

</style>