<template>
    <div class="cb-detail">
        <div class="row justify-content-between border-bottom pb-1">
            <div class="col-8 d-flex align-items-center">
                <span class="name">{{campBlock.name}}</span>
            </div>
            <div class="col-3 text-right">
                <span class="icon">
                    <el-tooltip :content="$t('campaignBlock.cbDetail.emailTooltip')" :open-delay="300" placement="top" v-if="campBlock.channels.includes('email')">
                        <span class="fa-regular fa-envelope fa-2x" aria-hidden="true"></span>
                    </el-tooltip>
                    <el-tooltip :content="$t('campaignBlock.cbDetail.smsTooltip')" :open-delay="300" placement="top" v-if="campBlock.channels.includes('sms')">
                        <span class="fa-regular fa-comment-dots fa-2x" aria-hidden="true"></span>
                    </el-tooltip>
                </span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-sm-4 col-xl-5">
                <div class="row">
                    <div class="col-12 description">
                        {{campBlock.description}}
                    </div>
                    <div class="col-12 lang-switch">
                        <div class="row border-bottom info-row justify-content-between">
                            <div class="col label">
                                {{$t('campaignBlock.cbDetail.labels.language')}}
                            </div>
                            <div class="col d-flex justify-content-end">
                                <el-select class="select-cb select-light" :value="campBlock.cultureCode" :disabled="CCs.length <= 1"
                                           @change="(cc) => updateCC(cc)">
                                    <el-option class="select-default"
                                               v-for="item in formatedCCs"
                                               :key="item.key"
                                               :label="item.label"
                                               :value="item.key">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 diff-switch mt-1">
                        <div class="row border-bottom info-row justify-content-between align-items-center">
                            <div class="col label">
                                {{$t('campaignBlock.cbDetail.labels.difficulty')}}
                            </div>
                            <div class="col d-flex flex-row justify-content-end">
                                <p-button :type="typeBtnDiff(1)" icon size="sm" class="diff-selector" :class="disableBtnDiff(1)" @click="updateDiff(1)">
                                    <i class="fa-solid fa-1"></i>
                                </p-button>
                                <p-button :type="typeBtnDiff(2)" icon size="sm" class="diff-selector" :class="disableBtnDiff(2)" @click="updateDiff(2)">
                                    <i class="fa-solid fa-2"></i>
                                </p-button>
                                <p-button :type="typeBtnDiff(3)" icon size="sm" class="diff-selector" :class="disableBtnDiff(3)" @click="updateDiff(3)">
                                    <i class="fa-solid fa-3"></i>
                                </p-button>
                                <p-button :type="typeBtnDiff(4)" icon size="sm" class="diff-selector" :class="disableBtnDiff(4)" @click="updateDiff(4)">
                                    <i class="fa-solid fa-4"></i>
                                </p-button>
                                <p-button :type="typeBtnDiff(5)" icon size="sm" class="diff-selector" :class="disableBtnDiff(5)" @click="updateDiff(5)">
                                    <i class="fa-solid fa-5"></i>
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 actions mt-1 info-row d-flex align-items-center">
                        <div class="">
                            <el-tooltip :content="$t('campaignBlock.cbDetail.btnSampleTooltip')" :open-delay="300" placement="top">
                                <p-button type="default" class="" icon @click="sendSample(campBlock.hid)">
                                    <i class="fa fa-paper-plane"></i>
                                </p-button>
                            </el-tooltip>
                        </div>
                        <div class="ml-1">
                            <el-tooltip :content="$t('campaignBlock.cbDetail.btnTPTooltip')" :open-delay="300" placement="top">
                                <a :href="campBlock.trainingPage.hostedDomainNameForSample" class="btn btn-icon btn-default" target="_blank">
                                    <i class="fa-brands fa-leanpub" aria-hidden="true"></i>
                                </a>
                            </el-tooltip>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-sm-8 col-xl-7">
                <div class="row justify-content-center h-100 align-items-center">
                    <div class="col-12 col-lg-5 text-center">
                        <div class="btn-preview" v-if="emailTemplateUrl != ''" @click="showPreview(emailTemplateUrl)">
                            <img :src="emailTemplateUrl" class="img-preview img-fluid" />
                            <div class="mt-1">{{$t('campaignBlock.cbDetail.labels.eTemplate')}}</div>
                        </div>
                        <div class="btn-preview" v-if="smsTemplateUrl != ''" @click="showPreview(smsTemplateUrl)">
                            <img :src="smsTemplateUrl" class="img-preview img-fluid" />
                            <div class="mt-1">{{$t('campaignBlock.cbDetail.labels.sTemplate')}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 text-center my-3 my-lg-0">
                        <i class="fa-solid fa-arrow-right-long fa-3x d-none d-lg-block"></i>
                        <i class="fa-solid fa-arrow-down-long fa-3x d-lg-none"></i>
                    </div>
                    <div class="col-12 col-lg-5 text-center">
                        <div class="btn-preview" @click="showPreview(landingPageUrl)">
                            <img :src="landingPageUrl" class="img-preview img-fluid" />
                            <div class="mt-1">{{$t('campaignBlock.cbDetail.labels.lp')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Select, Option, Button } from 'element-ui'
    import swal from 'sweetalert2'

    export default {
        name: "campaign-block-detail",
        components: {
            [Select.name]: Select,
            [Option.name]: Option,
            [Button.name]: Button,
        },
        props: {
            campaignBlock: {
                type: Object,
                required: true
            },
            entityHID: {
                type: String,
                required: true
            },
            diffs: {
                type: Array,
                default: []
            },
            CCs: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                trigger: 0,
            }
        },
        computed: {
            campBlock() {
                this.trigger;
                if (this.campaignBlock == undefined) {
                    return {
                        name: "",
                        description: "",
                        trainingPage: {
                            hostedDomainNameForSample: "",
                        },
                        selected: false,
                    }
                }
                return this.campaignBlock;
            },
            landingPageUrl() {
                this.trigger;
                if (this.campBlock.landingPage.galleryUrls.length > 0) {
                    return this.campBlock.landingPage.galleryUrls[0];
                }
                return "";
            },
            emailTemplateUrl() {
                this.trigger;
                if (this.campBlock.emailTemplate != null) {
                    if (this.campBlock.emailTemplate.galleryUrls.length > 0) {
                        return this.campBlock.emailTemplate.galleryUrls[0];
                    }
                }
                return "";
            },
            smsTemplateUrl() {
                this.trigger;
                if (this.campBlock.smsTemplate != null) {
                    if (this.campBlock.smsTemplate.galleryUrls.length > 0) {
                        return this.campBlock.smsTemplate.galleryUrls[0];
                    }
                }
                return "";
            },
            formatedCCs() {
                return this.CCs.map(c => { return { key: c, label: c.toUpperCase() } })
            },
        },
        methods: {
            refresh() {
                this.trigger++;
            },            
            sendSample(cbHID) {
                let vueThis = this;
                this.axios.post(this.$root.config.entity.campBlock.sendSampleUrl(this.entityHID), null, { params: { cbHID: cbHID } })
                    .then(response => {
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('campaignBlock.cbDetail.sampleAlert.success'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            let respMsg = error.response.data;
                            swal.fire({
                                title: vueThis.$t('campaignBlock.cbDetail.sampleAlert.failed'),
                                text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            typeBtnDiff(diff) {
                if (this.campBlock.difficulty == diff)
                    return "primary";
                if (this.diffs.some(d => d == diff))
                    return "default";
                return "neutral";             
            },
            disableBtnDiff(diff) {
                return this.diffs.some(d => d == diff) ? "" : "disabled";
            },
            showPreview(imgUrl) {
                this.$viewerApi({
                    images: [imgUrl],
                    options: {
                        "inline": true,
                        "button": true,
                        "navbar": false,
                        "title": false,
                        "toolbar": false,
                        "tooltip": true,
                        "movable": false,
                        "zoomable": false,
                        "rotatable": false,
                        "scalable": false,
                        "transition": false,
                        "fullscreen": false,
                        "keyboard": true
                    },
                });
            },
            //------------------
            updateCC(cc) {
                this.$emit('updateCC', cc);
            },
            updateDiff(diff) {
                this.$emit('updateDiff', diff);
            }
        },
    }


</script>

<style scoped>
    .img-preview {
        max-width: 200px !important;
    }
</style>