<template>
    <div ref="content" class="cbg-detail">
        <div class="row">
            <div class="col  d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'eCampaignBlockGroupList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">{{campaignBlockGroup.name}}</p>
                    <p class="category">{{ $t('cbgViews.cbg.header')}}</p>
                </div>
            </div>
            <div class="col d-flex flex-row justify-content-end">
                <el-tooltip :content="$t('cbgViews.cbg.btnCreateCampTooltip')" :open-delay="300" placement="top" v-show="manualControl && lazyAIStopped">
                    <router-link class="btn btn-icon btn-success" :to="{ name: 'newCampaign', query: { cbhid: campBlocksHID }}" data-v-step="step-btn-ncamp">
                        <i class="fa-solid fa-chart-gantt"></i>
                    </router-link>
                </el-tooltip>
            </div>
        </div>

        <card data-v-step="step-rt-cfg">
            <div class="row mb-4 mt-2">
                <div class="col-12 cbg-process-title">
                    <span>{{ $t('cbgViews.cbg.info.header', [campaignBlockGroup.sending + campaignBlockGroup.waiting])}}</span>
                </div>
                <div class="col-12 cbg-process">
                    <span class="sending" v-bind:style="{ width: (campaignBlockGroup.sending/(campaignBlockGroup.sending + campaignBlockGroup.waiting))*100 + '%' }">
                        {{ $t('cbgViews.cbg.info.sending', [campaignBlockGroup.sending ])}}
                    </span>
                    <span class="waiting" v-bind:style="{ width: (campaignBlockGroup.waiting/(campaignBlockGroup.sending + campaignBlockGroup.waiting))*100 + '%' }">
                        {{ $t('cbgViews.cbg.info.waiting', [campaignBlockGroup.waiting])}}
                    </span>
                </div>
            </div>
        </card>

        <div class="row">
            <div class="col-12" v-for="cb in sortedBlocks" :key="cb.id">
                <card data-v-step="step-details">
                    <campaign-block-detail :campaignBlock="cb" :diffs="[cb.difficulty]" :CCs="[cb.cultureCode]" :entityHID="entityHID"></campaign-block-detail>
                </card>
            </div>
        </div>

    </div>
</template>

<script>

    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, StatsCard, Badge } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import CampaignBlockDetail from 'src/components/UIComponents/CampaignBlock/CampaignBlockDetail.vue'
    import { LazyAITools } from 'src/lpLibrary.js'



    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            [Progress.name]: Progress,
            StatsCard,
            [Badge.name]: Badge,
            CampaignBlockDetail,
        },
        data() {
            return {
                entityHID: null,
                campBlocksHID: null,
                lazyAIStopped: false,
                manualControl: false,
                campaignBlockGroup: {},
            }
        },
        methods: {
            initComponent() {
                this.manualControl = this.$store.getters.appState.entity.manualControl;
                this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;
                this.loadCampBlock();
            },
            loadCampBlock: function () {
                if (this.campBlocksHID === undefined) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.entity.campBlockGroup.getCampaignBlockGroupDetailUrl(this.entityHID), null, { params: { cbgHID: this.campBlocksHID } })
                    .then(response => {
                        //console.log(response.data);
                        vueThis.campaignBlockGroup = response.data;                        
                        loader.hide();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
        },
        mounted: function () {
            this.entityHID = this.$route.params.ehid;
            this.campBlocksHID = this.$route.params.cbhid;
            this.initComponent();
        },
        computed: {
            sortedBlocks() {
                if (this.campaignBlockGroup == undefined || this.campaignBlockGroup.campaignBlocks == undefined) return [];
                return this.campaignBlockGroup.campaignBlocks.sort(function (a, b) { return a.order - b.order; });//asc
            },
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    if (!this.$store.getters.appState.entity.manualControl) {
                        this.$router.push({ name: "aDashboard" });
                    }
                    else {
                        this.$router.push({ name: 'eCampaignBlockGroupList' });
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>