<template>
    <div>
        <div class="mb-3" data-v-step="step-cfilter">
            <div class="btn-group d-none d-md-block">
                <p-button type="primary" round outline nativeLabel @click="changeView('v-dashboard')" :class="{active: view === 'v-dashboard' }"> <i class="fa-solid fa-building-columns"></i> {{$t('entityViews.setup.views.dashboard')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="changeView('v-domains')" :class="{active: view === 'v-domains' }"> <i class="fa-solid fa-globe"></i> {{$t('entityViews.setup.views.domains')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="changeView('v-whitelisting')" :class="{active: view === 'v-whitelisting' }"> <i class="fa-solid fa-filter"></i> {{$t('entityViews.setup.views.whitelisting')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="changeView('v-customization')" :class="{active: view === 'v-customization' }"> <i class="fa fa-pause"></i> {{$t('entityViews.setup.views.customization')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('v-dashboard')" :class="{active: view === 'v-dashboard' }"> <i class="fa-solid fa-building-columns"></i> {{$t('entityViews.setup.views.dashboard')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('v-domains')" :class="{active: view === 'v-domains' }"> <i class="fa-solid fa-globe"></i> {{$t('entityViews.setup.views.domains')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('v-whitelisting')" :class="{active: view === 'v-whitelisting' }"> <i class="fa-solid fa-filter"></i> {{$t('entityViews.setup.views.whitelisting')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="changeView('v-customization')" :class="{active: view === 'v-customization' }"> <i class="fa fa-pause"></i> {{$t('entityViews.setup.views.customization')}}</p-button>
                </div>
            </div>
        </div>

        <div class="row" v-if="view === 'v-dashboard'">
            <div class="col-12">
                <setup-dashboard :entityHID="entityHID"></setup-dashboard>
            </div>
        </div>
        <div class="row" v-if="view === 'v-domains'">
            <div class="col-12">
                <domains :entityHID="entityHID"></domains>
            </div>
        </div>
        <div class="row" v-if="view === 'v-whitelisting'">
            <div class="col-12">
                <whitelisting></whitelisting>
            </div>
        </div>

        <div class="row" v-if="view === 'v-customization'">
            <div class="col-12">
                <customization :entityHID="entityHID"></customization>
            </div>
        </div>

    </div>
</template>

<script>
    import { Input, Select, Option, Upload, ColorPicker } from 'element-ui'
    import { Card, } from 'src/components/UIComponents'
    import custView from 'src/components/UIComponents/Setup/Customization.vue'
    import whitelistingView from 'src/components/UIComponents/Setup/Whitelisting.vue'
    import domainsView from 'src/components/UIComponents/Setup/Domains.vue'
    import dashboardView from 'src/components/UIComponents/Setup/Dashboard.vue'

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Upload.name]: Upload,
            [ColorPicker.name]: ColorPicker,
            [custView.name]: custView,
            [whitelistingView.name]: whitelistingView,
            [domainsView.name]: domainsView,
            [dashboardView.name]: dashboardView,
        },
        data() {
            return {
                view: "v-dashboard",
                entityHID: null,
            }
        },
        methods: {
            changeView(view) {
                if (this.view != view) {
                    this.$router.push({ name: 'eSetup', query: { v: view } });
                }
                else {
                    this.view = view;
                }
            },
        },
        computed: {
        },
        mounted: function () {
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    let v = this.$route.query.v;
                    if (v == undefined) v = 'v-dashboard';
                    this.view = v;
                }
            },
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            let v = this.$route.query.v;
            if (v == undefined) v = 'v-dashboard';
            this.view = v;
        }
    }

</script>

<style scoped>


</style>