<template>
    <div>
        <div class="card" >
            <div class="card-header">
                <div class="row">
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{title}}</h4>
                    </div>
                    <div class="col text-right">
                        <el-tooltip :content="$t('hvResult.hvTable.btnRefreshTooltip')" :open-delay="300" placement="top" v-if="allowLoading">
                            <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="text-muted">
                            {{description}}
                        </p>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row" ref="table">
                    <div class="col-12" v-if="allowLoading">
                        <!-- toolbar -->
                        <div class="row toolbar">
                            <div class="col-12 d-flex flex-row">
                                <slot name="toolbar-start">

                                </slot>

                                <slot name="toolbar-end">

                                </slot>
                            </div>
                        </div>
                        <!-- select -->
                        <div class="row justify-content-between">
                            <div class="col-12 col-md-6 pt-0 pt-md-2">

                            </div>
                            <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                                <div class="d-flex justify-content-end">
                                    <fg-input class="input-sm mr-2" :disabled="dataLoading"
                                              :placeholder="$t('hvResult.hvTable.searchPlaceholder')"
                                              v-model="searchQuery"
                                              addon-right-icon="nc-icon nc-zoom-split">
                                    </fg-input>
                                    <el-select class="select-default float-sm-left page-size"
                                               v-model="pagination.perPage">
                                        <el-option class="select-default"
                                                   v-for="item in pagination.perPageOptions"
                                                   :key="item"
                                                   :label="item"
                                                   :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>

                        </div>
                        <!-- table -->
                        <div class="row">
                            <div class="col-12">
                                <el-table :data="hoaxVictim" :empty-text="$t('hvResult.hvTable.table.noData')"
                                            :default-sort="sortTable"
                                            @sort-change="handleSortChange" >
                                    <el-table-column type="expand" v-if="viewBy == 'campaign'">
                                        <template slot-scope="props">
                                            <!--<div v-if="viewBy == 'entity'" class="mb-5">
                                                <hv-score-chart :hvHID="props.row.hid" :entityHID="entityHID"></hv-score-chart>
                                            </div>-->
                                            <div>
                                                <hv-result-table :hvHID="props.row.hid" :campaignHID="campaignHID" :hvGroupHID="hvGroupHID" :entityHID="entityHID" :viewBy="viewBy"></hv-result-table>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name" sortable="custom" :label="$t('hvResult.hvTable.table.colName')">
                                    </el-table-column>
                                    <el-table-column prop="email" sortable="custom" :label="$t('hvResult.hvTable.table.colEmail')">
                                    </el-table-column>
                                    <el-table-column prop="phoneNumber" width="150" :label="$t('hvResult.hvTable.table.colPNumber')" align="center">
                                    </el-table-column>
                                    <el-table-column prop="score" sortable="custom" width="130" align="center">
                                        <template slot="header">
                                            <span>
                                                {{scoreColTitle}}
                                            </span>
                                        </template>
                                        <template slot-scope="props">
                                            <div v-if="props.row.score != null">
                                                <div class="score-icon" v-bind:style="[props.row.scoreColor]" v-if="viewBy != 'campaign'"></div>
                                                {{props.row.score}}
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :width="120" align="center" class-name="td-actions" v-if="viewBy === 'entity' || viewBy === 'group'">
                                        <template slot="header" slot-scope="scope">
                                            <span data-v-step="step-hvsactions">{{$t('hvResult.hvTable.table.colActions.header')}}</span>
                                        </template>
                                        <template slot-scope="props">
                                            <el-tooltip :content="$t('hvResult.hvTable.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                                <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'eHVReport', params:{ hvhid: props.row.hid}}">
                                                    <i class="fa fa-pie-chart"></i>
                                                </router-link>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </div>
                        </div>
                        <!-- paging -->
                        <div class="row">
                            <div class="col-sm-6 pagination-info">
                                <p class="category">{{$t('hvResult.hvTable.pagingInfo', [from + 1, to, pagination.total])}}</p>
                            </div>
                            <div class="col-sm-6">
                                <p-pagination class="pull-right"
                                                v-model="pagination.currentPage"
                                                :per-page="pagination.perPage"
                                                :total="pagination.total">
                                </p-pagination>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 text-center" v-else>
                        <p-button type="neutral" @click="manualLoad()">
                            <el-empty>
                                <span slot="image">
                                    <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
                                </span>
                                <p-button slot="description" type="primary">
                                    {{$t('hvResult.hvTable.btnLoadLabel')}}
                                </p-button>
                            </el-empty>
                        </p-button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, Card, TableColumn, Empty } from 'element-ui'
    import { Modal, Pagination } from 'src/components/UIComponents'
    import hvDetail from 'src/components/UIComponents/ModalDialog/HoaxVictimDetail'
    import hvResult from 'src/components/UIComponents/HVResult/HVResultTable.vue'
    import hvScoreChart from 'src/components/UIComponents/Charts/HVScoreChart.vue'

    import swal from 'sweetalert2'

    export default {
        name: 'hv-table',
        props: {
            entityHID: {
                type: String,
                required: true
            },
            autoLoading: {
                type: Boolean,
                default: true,
            },
            hvGroupHID: String,
            campaignHID: String,
            title: String,
            description: String,
            viewBy: {
                type: String,
                default: 'entity',
                description: '[entity|campaign|group]'
            },
            orderBy: {
                type: String,
                default: 'name',
                description: '[name|score]'   
            }
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            [hvResult.name]: hvResult,
            [hvDetail.name]: hvDetail,
            [hvScoreChart.name]: hvScoreChart,
            [Empty.name]: Empty,
        },
        data() {
            return {
                hoaxVictim: [],
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [25, 50, 100, 200, 500],
                    total: 0,
                },
                propsToSearch: ['name', 'email'],
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'name' },
                sortTable: { prop: 'name', order: 'ascending' },
                timeoutQuery: null,
                manualLoading: false,
                dataLoading: false,
            }
        },
        methods: {
            initComponent: function (force) {
                if (this.autoLoading) {
                    this.loadHoaxVictim(force);
                }
            },
            loadHoaxVictim: function (force) {
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = null;

                let filter = {
                    pageSize: this.pagination.perPage,
                    page: this.pagination.currentPage-1,
                    searchPattern: this.searchQuery,
                    orderBy: this.sortObject.name,
                    orderDirection: this.sortObject.order,
                };

                switch (this.viewBy) {
                    case 'campaign':
                        if (this.campaignHID == undefined && this.campaignHID == null)
                            return;
                        loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                        this.dataLoading = true;
                        filter.objectHID = this.campaignHID;
                        this.axios.post(this.$root.config.entity.campaign.getHoaxVictimsUrl(this.entityHID), filter, { params: { force: force } })
                            .then(response => {
                                loader.hide();
                                vueThis.dataLoading = false;
                                this.pagination.total = response.data.total;
                                this.hoaxVictim = response.data.records;
                                this.hoaxVictim.forEach(function (item) {
                                    item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                                });                                
                            }).catch(function (error) {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                                loader.hide();
                                vueThis.dataLoading = false;
                            });
                        break;
                    case 'entity':
                        if (this.entityHID == undefined && this.entityHID == null)
                            return;
                        //loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);

                        this.dataLoading = true;
                        filter.objectHID = this.entityHID;
                        this.axios.post(this.$root.config.entity.report.getHoaxVictimsByEntityUrl(this.entityHID), filter, { params: { force: force } })
                            .then(response => {
                                //loader.hide();
                                this.pagination.total = response.data.total;
                                this.hoaxVictim = response.data.records;
                                this.hoaxVictim.forEach(function (item) {
                                    item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                                });
                                
                                vueThis.dataLoading = false;
                                swal.close();
                            }).catch(function (error) {
                                swal.close();
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                                vueThis.dataLoading = false;
                                //loader.hide();
                            });
                        break;
                    case 'group':
                        if (this.hvGroupHID == undefined && this.hvGroupHID == null)
                            return;
                        loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));
                        this.dataLoading = true;
                        filter.objectHID = this.hvGroupHID;
                        this.axios.post(this.$root.config.entity.report.getHoaxVictimsByHvGroupUrl(this.entityHID), filter, { params: { force: force } })
                            .then(response => {
                                loader.hide();
                                vueThis.dataLoading = false;
                                this.pagination.total = response.data.total;
                                this.hoaxVictim = response.data.records;
                                this.hoaxVictim.forEach(function (item) {
                                    item.scoreColor = { backgroundColor: `hsl(${item.score}, 95%, 50%)` };
                                });
                                
                            }).catch(function (error) {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                                vueThis.dataLoading = false;
                                loader.hide();
                            });
                        break;
                }
            },
            refresh(force) {
                if (this.manualLoading || this.autoLoading) {
                    this.loadHoaxVictim(force);
                }
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };

            },
            manualLoad() {
                this.manualLoading = true;
                this.loadHoaxVictim();
            },
        },
        watch: {
            hvGroupHID: function () {
                if (this.autoLoading) {
                    this.loadHoaxVictim();
                }
            },
            searchQuery: function () {
                let vueThis = this;
                if (this.timeoutQuery != null) {
                    clearTimeout(this.timeoutQuery);
                }
                this.timeoutQuery = setTimeout(function () { vueThis.loadHoaxVictim(); }, vueThis.$root.config.searchWaitingAction);

            },
            sortObject: {
                deep: true,
                handler: function () {
                    this.loadHoaxVictim();
                }
            },
            'pagination.currentPage': function() {
                this.loadHoaxVictim();
            },
            'pagination.perPage': function () {
                if (this.pagination.currentPage == 1) {
                    this.loadHoaxVictim();
                }
            }
        },
        mounted: function () {
            //this.initComponent();
        },
        computed: {
            allowLoading() {
                if (this.manualLoading)
                    return true;
                if (!this.manualLoading && this.autoLoading)
                    return true;
                return false;
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.pagination.total < highBound) {
                    highBound = this.pagination.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            scoreColTitle() {
                switch (this.viewBy) {
                    case 'campaign': return this.$t('hvResult.hvTable.table.colPoints');
                    default: return this.$t('hvResult.hvTable.table.colScore')
                }
            },
        },
        created() {
            if (this.orderBy == 'score') {
                this.sortTable = { prop: 'score', order: 'ascending' };
                this.sortObject =  { order: 'ascending', name: 'score' };
            }
        },
    }
</script>

<style scoped>
</style>