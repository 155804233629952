<template>
    <div ref="groupContent">
        <div class="row">
            <div class="col  d-flex justify-content-start">
                <router-link class="btn btn-info btn-icon btn-round btn-lg" :to="{ name: 'eHVGroupsList'}">
                    <i class="fa fa-arrow-left "></i>
                </router-link>
                <div class="view-title ml-3" data-v-step="step-name">
                    <p class="title">{{translateGroupName(hvGroup.name, hvGroup.isSystem)}}</p>
                    <p class="category">{{$t('entityViews.hvGroup.header')}}</p>
                </div>
            </div>
            <div class="col d-flex flex-row justify-content-end">
                <el-tooltip :content="$t('entityViews.hvGroup.btnNewCampTooltip')" :open-delay="300" placement="top" v-show="manualControl && lazyAIStopped">
                    <router-link class="btn btn-icon btn-success mr-2" :to="{ name: 'newCampaign', query: { ghid: groupHID }}">
                        <i class="fa-solid fa-chart-gantt"></i>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.hvGroup.btnEditTooltip')" :open-delay="300" placement="top" v-show="!hvGroup.isSystem">
                    <p-button type="default" class="mr-2" icon @click="editHvGroup()">
                        <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
                    </p-button>
                </el-tooltip>
                <upload-button :entityHID="entityHID" mode="toGroup" :groupHID="groupHID" :hvGroupHasRunningCampaign="hvGroup.hasRunningCampaign"
                                color="white" backgroundColor="#fbc658"
                                :url="uploadUrl"
                                icon="fa fa-user-plus" @uploadFinished="uploadFinished"
                                :tooltipText="$t('entityViews.hvGroup.btnUploadTooltip')" tooltipPlacement="top" class="mr-2"
                                v-if="!hvGroup.isSystem">
                </upload-button>
                <el-tooltip :content="$t('entityViews.hvGroup.btnRefreshTooltip')" :open-delay="300" placement="top">
                    <p-button type="default" class="mr-2" icon @click="refresh(true)" >
                        <i class="fa-solid fa-arrows-rotate"></i>
                    </p-button>
                </el-tooltip>
                <el-tooltip :content="$t('entityViews.hvGroup.btnDeleteTooltip')" v-show="!hvGroup.isSystem" :open-delay="300" placement="top">
                    <p-button type="danger" icon @click="deleteHvGroup()"  >
                        <i class="fa-solid fa-trash-can"></i>
                    </p-button>
                </el-tooltip>
            </div>
        </div>


        <div class="row">
            <div class="col-md-6 col-12 ">
                <stats-card :type="domainCardType" data-v-step="step-domainstatus"
                            :title="domainCardLabel"
                            :small-title="$t('entityViews.hvGroup.domainCardHeader')"
                            :icon="domainCardIcon">
                </stats-card>
            </div>
            <div class="col-md-6 col-12 ">
                <stats-card type="success" data-v-step="step-emps"
                            :title="hvGroup.countHoaxVictim.toString()"
                            :small-title="$t('entityViews.hvGroup.hvsCardHeader')"
                            icon="fa fa-user">
                </stats-card>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hv-manage-table :hvGroupHID="groupHID" ref="hvTable" :entityHID="entityHID" viewBy="group" :title="$t('entityViews.hvGroup.hvTableHeader')" :autoLoading="false" :groupIsSystem="hvGroup.isSystem">
                    <template v-slot:toolbar-start>
                        <upload-button :entityHID="entityHID" mode="toGroup" :groupHID="groupHID" :hvGroupHasRunningCampaign="hvGroup.hasRunningCampaign"
                                       color="white" backgroundColor="#fbc658"
                                       :url="uploadUrl" data-v-step="step-hvsupload"
                                       icon="fa fa-user-plus" @uploadFinished="uploadFinished" :btnLabel="$t('entityViews.hvGroup.toolsbar.btnUpload')"
                                       tooltipPlacement="top" v-if="!hvGroup.isSystem">
                        </upload-button>
                    </template>
                    <template v-slot:toolbar-end>
                        <p-button type="danger" @click="clearGroup" data-v-step="step-cleargroup" v-if="!hvGroup.isSystem">
                            <i class="fa fa-times"></i> {{$t('entityViews.hvGroup.toolsbar.btnClear')}}
                        </p-button>
                    </template>
                </hv-manage-table>
            </div>
        </div>

    </div>

</template>

<script>
    import { CampaignTools, HvGroupTools, MaturityLevelTools, LazyAITools } from 'src/lpLibrary.js'

    import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue'
    import HvManageTable from 'src/components/UIComponents/HVManageTable.vue'
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, StatsCard } from 'src/components/UIComponents'
    import UploadCard from 'src/components/UIComponents/Cards/UploadCard.vue'
    import UploadButton from 'src/components/UIComponents/UploadButton.vue'
    import PPagination from 'src/components/UIComponents/Pagination.vue'



    import swal from 'sweetalert2'
    import { round } from 'd3'

    function c_sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }


    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Progress.name]: Progress,
            CircleChartCard,
            StatsCard,
            [HvManageTable.name]: HvManageTable,
            UploadCard,
            [UploadButton.name]: UploadButton,
            PPagination,
        },
        data() {
            return {
                entityHID: '',
                groupHID: '',
                manualControl: false,
                lazyAIStopped: false,
                hvGroup: {
                    campaigns: [],
                    countHoaxVictim:0,
                },
                modals: {
                    newCampaignModal: false,
                    firstAvailableDate: new Date(Date.now()),
                },
                //-------------------
                c_pagination: {
                    perPage: 5,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                c_propsToSearch: ['name', 'order'],
                c_searchQuery: '',
                c_sortObject: { order: 'descending', name: 'order' },
            }
        },
        computed: {
            domainCardType() {
                return this.hvGroup.checkedDomains ? "success" : "danger";
            },
            domainCardIcon() {
                return this.hvGroup.checkedDomains ? "nc-icon nc-check-2" : "nc-icon nc-simple-remove";
            },
            domainCardLabel() {
                return this.hvGroup.checkedDomains ? this.$t('entityViews.hvGroup.domainsValid') : this.$t('entityViews.hvGroup.domainsNotValid');
            },
            countActiveCamp() {
                return this.hvGroup.campaigns.filter(c => c.isRunning == true || c.isScheduled == true || c.isStopped == true).length;
            },
            uploadUrl() {
                return this.$root.config.entity.uploadHvsUrl(this.entityHID);
            },
            byMessages() {
                if (this.campaign.statistic == undefined) return {};
                return this.campaign.statistic.byMessages;
            },
            //--------------------------
            c_queriedData() {
                var cThis = this;
                if (!this.c_searchQuery) {
                    this.c_pagination.total = this.hvGroup.campaigns.length;
                    return this.hvGroup.campaigns.sort(function (a, b) { return c_sortingHandler(a, b, cThis.c_sortObject); }).slice(this.c_from, this.c_to);
                }
                let result = this.hvGroup.campaigns
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.c_propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.c_searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.c_pagination.total = result.length;
                return result.sort(function (a, b) { return c_sortingHandler(a, b, cThis.c_sortObject); }).slice(this.c_from, this.c_to);
            },
            c_to() {
                let highBound = this.c_from + this.c_pagination.perPage;
                if (this.c_total < highBound) {
                    highBound = this.c_total;
                }
                return highBound;
            },
            c_from() {
                if (this.c_pagination.total == 0) return -1;
                return this.c_pagination.perPage * (this.c_pagination.currentPage - 1);
            },
            c_total() {
                return this.c_pagination.total;
            },
        },
        methods: {
            loadHvGroup: function (force) {
                if (force == undefined) force = false;
                if (this.groupHID === undefined) return;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.groupContent));
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupDetailUrl(this.entityHID), null, { params: { groupHID: this.groupHID, force: force } })
                    .then(response => {
                        this.hvGroup = response.data;                       
                        loader.hide();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            deleteHvGroup: async function () {
                let vueThis = this;
                if (this.hvGroup.hasActiveCampaign) {
                    swal.fire({
                        icon: 'error',
                        title: vueThis.$t('common.delGroupAlert.errActiveCamp.msg', [this.hvGroup.name]),
                        text: vueThis.$t('common.delGroupAlert.errActiveCamp.text'),
                        showCancelButton: false,
                    });
                    return;
                }

                if (this.hvGroup.countCampaigns > 0) {
                    let { value: confirm } = await swal.fire({
                        icon: 'warning',
                        title: vueThis.$t('common.delGroupAlert.warnHasCamp.msg'),
                        text: vueThis.$t('common.delGroupAlert.warnHasCamp.text'),
                        showCancelButton: false,
                    });
                }

                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('common.delGroupAlert.msg',);
                cfg.text = vueThis.$t('common.delGroupAlert.text', [this.hvGroup.name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                        vueThis.axios.post(vueThis.$root.config.entity.hvGroup.hvGroupDeleteUrl(vueThis.entityHID), null, { params: { groupHID: vueThis.hvGroup.hid } })
                            .then(response => {
                                swal.close();
                                vueThis.$router.push({ name: 'eHVGroupsList' });
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('common.delGroupAlert.success', [vueThis.hvGroup.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                swal.close();
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('common.delGroupAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            uploadFinished: function (response, errCode, errMsg, errObj) {
                let vueThis = this;
                if (response) {
                    this.$refs.hvTable.refresh();
                    swal.fire({
                        icon: 'success',
                        title: vueThis.$t('common.uploadHvsAlert.success'),
                        showConfirmButton: true,
                        timer: this.$root.config.alertTimer15,
                        timerProgressBar: true,
                    });
                }
                else {
                    // 500001, 500002, 500003, 500101, 500202, 500102, 500104
                    if (errObj == null || errObj == undefined) {
                        errObj = { domaiName: "NaN", email: "NaN", phoneNumber: "NaN" };
                    }
                    let respMsg = response.data;
                    swal.fire({
                        title: vueThis.$t('common.uploadHvsAlert.failed'),
                        text: vueThis.$t('apiErrorCode.' + respMsg.errorCode + '.msg', { name: errObj.domainName, email: errObj.email, phoneNumber: errObj.phoneNumber }),
                        icon: vueThis.$t('apiErrorCode.' + respMsg.errorCode + '.icon'),
                        showConfirmButton: true,
                        timer: vueThis.$root.config.alertTimer15,
                        timerProgressBar: true,
                    });
                }
            },
            refresh: function (force) {
                this.loadHvGroup(force);
                this.$refs.hvTable.refresh(force);
            },
            editHvGroup() {
                var vueThis = this;
                let hvgName = this.hvGroup.name;
                swal.fire({
                    title: vueThis.$t('entityViews.hvGroup.editHvGroupAlert.msg'),
                    input: 'text',
                    inputValue: hvgName,
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return vueThis.$t('entityViews.hvGroup.editHvGroupAlert.errReq');
                        }
                        if (value.trim().length > this.$root.config.maxHvGroupNameLength) {
                            return vueThis.$t('entityViews.hvGroup.editHvGroupAlert.errMax', [this.$root.config.maxHvGroupNameLength]);
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.entity.hvGroup.renameHvGroupUrl(vueThis.entityHID), null, { params: { groupHID: vueThis.hvGroup.hid, name: result.value.trim() } })
                        .then(response => {
                            vueThis.refresh();
                            swal.fire({
                                icon: 'success',
                                title: vueThis.$t('entityViews.hvGroup.editHvGroupAlert.success'),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });                            
                        })
                        .catch(function (error) {
                            if (error.response.status == 400) {
                                if (result == null || result == undefined) {
                                    result = { value: "NaN" };
                                }
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('entityViews.hvGroup.editHvGroupAlert.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: result.value }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },
            clearGroup() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('entityViews.hvGroup.clearHvGroupAlert.msg');
                if (this.hvGroup.hasRunningCampaign) {
                    cfg.text = vueThis.$t('entityViews.hvGroup.clearHvGroupAlert.text');
                }
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        swal.fire(this.$root.config.getSweetAlertCfg_Waiting);

                        this.axios.post(this.$root.config.entity.hvGroup.hoaxVictimGroupClearUrl(this.entityHID), null, { params: { groupHID: this.groupHID } })
                            .then(response => {
                                vueThis.refresh();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('entityViews.hvGroup.clearHvGroupAlert.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    swal.fire({
                                        icon: 'error',
                                        title: vueThis.$t('entityViews.hvGroup.clearHvGroupAlert.failed'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
            //-----------------------------
            c_handleSortChange(e) {
                this.c_sortObject = { order: e.order, name: e.prop };
            },

        },
        mounted: function () {
            this.manualControl = this.$store.getters.appState.entity.manualControl;
            this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;
            this.loadHvGroup();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.$router.push({ name: 'eHVGroupsList' });
                }
            }
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            this.groupHID = this.$route.params.ghid;
        }
    }

</script>

<style scoped>
    .btn-campaign-control{
        margin: 0px;
    }

</style>
