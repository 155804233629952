<template>
    <el-dropdown @visible-change="ddOpen">
        <span class="el-dropdown-link">
            {{title}}
            <span class="mx-1" v-if="countSelectedItems > 0">({{countSelectedItems}})</span>

            <i class="fa-solid fa-angle-up" v-if="ddMenuOpened"></i>
            <i class="fa-solid fa-angle-down" v-else></i>

        </span>
        <el-dropdown-menu slot="dropdown" class="pr-2 pl-2">
            <p-checkbox v-for="(item, index) in items" :key="item" :checked="isChecked(item)" @input="checkItem(item)">{{formattingLabel(item)}}</p-checkbox>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { Dropdown, DropdownMenu } from 'element-ui'

    export default {
        name: 'dropdown-check',
        components: {
            [Dropdown.name]: Dropdown,
            [DropdownMenu.name]: DropdownMenu,
        },
        props: {
            title: {
                type: String,
            },
            items: {
                type: Array,
                required: true
            },
            value: {
                type: [Array],                
            },
            formatLabel: {
                type: String,
                default: 'normal',
                description: 'normal|toUpper|toLower'
            }
        },
        data() {
            return {
                ddMenuOpened: false,
            }
        },
        methods: {
            ddOpen(e) {
                this.ddMenuOpened = e;
            },
            isChecked(item) {
                if (this.model == undefined) return false;

                return this.model.includes(item);
            },
            checkItem(item) {
                if (this.model == undefined) this.model = [];

                if (this.model.includes(item)) {
                    this.model = this.model.filter(c => c != item);
                }
                else {
                    this.model.push(item);
                }
            },
            formattingLabel(label) {
                switch (this.formatLabel) {
                    case 'toUpper':
                        return label.toUpperCase();
                    case 'toLower':
                        return label.toLowerCase();
                }
                return label;
            }
        },
        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            countSelectedItems() {
                if (this.model == undefined) return 0;
                return this.model.length;
            }
        },
    }

</script>

<style scoped>
</style>