<template>
    <div>
        <el-select class="select-default float-sm-right"
                   v-model="zonefilter">
            <el-option class="select-default"
                       v-for="item in zoneSelectors"
                       :key="item.key"
                       :label="item.label"
                       :value="item.key">
            </el-option>
        </el-select>
        <bar-chart :labels="chartXAxisLabel" ref="barChart"
                   :height="100"
                   :datasets="chartDataSets" :extraOptions="extraOptions">
        </bar-chart>
    </div>
</template>

<script>
    import BarChart from 'src/components/UIComponents/Charts/BarChart'
    import { Select, Option } from 'element-ui'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'hourly-distribution-chart',
        props: {
            chartData: Array,
        },
        components: {
            BarChart,
            [Select.name]: Select,
            [Option.name]: Option,
        },
        data() {
            let vueThis = this;
            return {
                extraOptions: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        //display: false,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }],
                        xAxes: [{
                            //scaleLabel: {
                            //    display: true,
                            //    labelString: 'UTC'
                            //}
                        }],
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                    },
                },
                zoneSelectors: [
                    { label: this.$t('charts.hourlyDistChart.filter.utc'), key: "utc" },
                    { label: this.$t('charts.hourlyDistChart.filter.local'), key: "local" },
                ],
                zonefilter: 'utc',

            }
        },
        methods: {
            localizeZoneSelectors() {
                this.zoneSelectors.forEach((item) => {
                    if (item.key == "local") {
                        item.label = item.label + "+" + (this.$moment().zone() / -60) +")";
                    }
                });
            },
        },
        computed: {
            preprocessedData() {
                if (this.chartData == undefined) return [];
                if (this.zonefilter === 'local') {
                    let zoneShift = this.$moment().zone() / -60;
                    let localChartData = JSON.parse(JSON.stringify(this.chartData));
                    localChartData.forEach(item => {
                        item.hour = (item.hour + zoneShift) % 24;
                    });
                    return localChartData;
                }
                return this.chartData;
            },
            orderedChartData() {
                return this.preprocessedData.sort(function (a, b) { return sortingHandler(a, b, { order: 'ascending', name: 'hour' }); });
            },
            chartXAxisLabel() {
                return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
            },
            chartInitiatedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countInitiated);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.initiatedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#6bd098',
                    borderColor: '#6bd098',
                };

            },
            chartInteractedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countInteracted);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.interactedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#fbc658',
                    borderColor: '#fbc658',
                };
            },
            chartReactedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countReacted);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.reactedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#ffa500',
                    borderColor: '#ffa500',
                };
            },
            chartCompromisedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countCompromised);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.compromisedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#ef8157',
                    borderColor: '#ef8157',
                };
            },
            chartTrainedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countTrained);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.trainedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#51bcda',
                    borderColor: '#51bcda',
                };
            },
            chartReportedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countReported);
                    }
                }
                return {
                    label: this.$t('charts.hourlyDistChart.reportedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#e2f6eb',
                    borderColor: '#e2f6eb',
                };
            },
            chartDataSets() {
                return [
                    this.chartInitiatedData,
                    this.chartInteractedData,
                    this.chartReactedData,
                    this.chartCompromisedData,
                    this.chartTrainedData,
                    this.chartReportedData,
                ];
            },
        },
        mounted() {
            this.localizeZoneSelectors();
        }
    }
</script>

<style scoped>
</style>