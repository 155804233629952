<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-12">
                <stats-card type="default" data-v-step="step-esc-init"
                            icon="fa fa-user">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.hvsTraining')}}
                            </p>
                            <p class="card-title">{{titleInitiated}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
            <div class="col-md-4 col-12">
                <stats-card type="interacted" data-v-step="step-esc-inter"
                            icon="fa-solid fa-envelope-open-text">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.interactedLabel')}}
                                <el-tooltip :open-delay="300" placement="top">
                                    <span slot="content" v-html="$t('channelStatCardsByHvs.interactedTooltip')">
                                    </span>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </el-tooltip>
                            </p>
                            <p class="card-title">{{titleInteracted}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
            <div class="col-md-4 col-12">
                <stats-card type="reacted" data-v-step="step-esc-reacted"
                            icon="fa-solid fa-hand-point-up">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.reactedLabel')}}
                                <el-tooltip :open-delay="300" placement="top">
                                    <span slot="content" v-html="$t('channelStatCardsByHvs.reactedTooltip')">
                                    </span>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </el-tooltip>
                            </p>
                            <p class="card-title">{{titleReacted}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
        </div>

        <div class="row row5 mt-3 mt-sm-0">
            <div class="col-md-4 col-12">
                <stats-card type="compromised" data-v-step="step-esc-compromised"
                            icon="fa fa-bomb">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.compromisedLabel')}}
                                <el-tooltip :open-delay="300" placement="top">
                                    <span slot="content"  v-html="$t('channelStatCardsByHvs.compromisedTooltip')">
                                    </span>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </el-tooltip>
                            </p>
                            <p class="card-title">{{titleCompromised}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
            <div class="col-md-4 col-12">
                <stats-card type="trained" data-v-step="step-esc-trained"
                            icon="fa fa-graduation-cap">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.trainedLabel')}}
                                <el-tooltip :open-delay="300" placement="top">
                                    <span slot="content"  v-html="$t('channelStatCardsByHvs.trainedTooltip')">
                                    </span>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </el-tooltip>
                            </p>
                            <p class="card-title">{{titleTrained}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
            <div class="col-md-4 col-12">
                <stats-card type="reported" data-v-step="step-esc-reported"
                            icon="fa fa-flag">
                    <template v-slot:content>
                        <div class="numbers">
                            <p class="card-category">
                                {{$t('channelStatCardsByHvs.reportedLabel')}}
                                <el-tooltip :open-delay="300" placement="top">
                                    <span slot="content"  v-html="$t('channelStatCardsByHvs.reportedTooltip')">

                                    </span>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </el-tooltip>
                            </p>
                            <p class="card-title">{{titleReported}}</p>
                        </div>
                    </template>
                </stats-card>
            </div>
        </div>
    </div>
</template>

<script>

    import { Card, StatsCard } from 'src/components/UIComponents'


    export default {
        name: 'channel-statistic-cards-byhvs',
        components: {
            Card,
            StatsCard,
        },
        props: {
            statistic: {
                type: Object
            },
            showInitiatedProc: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            titleInitiated() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                if (this.showInitiatedProc) {
                    return `${this.statistic.countInitiated} (${(this.statistic.percentInitiated * 100).toFixed(1)} %)`;
                }
                else {
                    return `${this.statistic.countInitiated}`;
                }

            },
            titleInteracted() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                return `${this.statistic.countInteracted} (${(this.statistic.percentInteracted * 100).toFixed(1)} %)`;
            },
            titleReacted() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                return `${this.statistic.countReacted} (${(this.statistic.percentReacted * 100).toFixed(1)} %)`;
            },
            titleCompromised() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                return `${this.statistic.countCompromised} (${(this.statistic.percentCompromised * 100 ).toFixed(1) } %)`;
            },
            titleReported() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                return `${this.statistic.countReported} (${(this.statistic.percentReported * 100).toFixed(1) } %)`;
            },
            //titleReInteracted() {
            //    if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
            //    return this.statistic.countReInteracted.toString();
            //},
            //titleReReacted() {
            //    if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
            //    return this.statistic.countReReacted.toString();
            //},
            //titleReCompromised() {
            //    if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
            //    return this.statistic.countReCompromised.toString();
            //},
            titleTrained() {
                if (this.statistic == undefined || this.statistic.hoaxVictims == 0) return "0";
                return `${this.statistic.countTrained} (${(this.statistic.percentTrained * 100).toFixed(1)} %)`;
            },
        },
    }


</script>

<style scoped>
</style>