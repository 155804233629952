<template>
    <div ref="lazyAIcheck">
        <!-- HV -->
        <div class="row">
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.hv.color" title="" :small-title="$t('setup.dashboard.hv.cc1.header')" icon="fa fa-users fa-2x">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <router-link class="btn btn-default btn-block" :to="{ name: 'eHVGroupsList'}">
                                {{$t('setup.dashboard.hv.cc1.btnLabel')}}
                            </router-link>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.hv.color" :small-title="lazyAICheckUI.hv.title" :icon="lazyAICheckUI.hv.icon" :showSeparator="false" :coloredSmallTitle="true">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <p-button type="warning" block @click="markHvListAsValid" v-if="lazyAICheckUI.hv.showHvListValidBtn">
                                {{$t('setup.dashboard.hv.cc2.btnHvValid')}}
                            </p-button>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <div>
                    <stats-card type="default" :title="lazyAICheckUI.hv.hvCount" :small-title="$t('setup.dashboard.hv.cc3.totalHv')" icon="fa fa-users">
                    </stats-card>
                </div>
                <div>
                    <stats-card type="default" :title="lazyAICheckUI.hv.lastUpdateUtc" :small-title="$t('setup.dashboard.hv.cc3.hvlastUpdate')" icon="fa fa-users">
                    </stats-card>
                </div>
            </div>
        </div>

        <!-- Domain -->
        <div class="row mt-3">
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.domain.color" title="" :small-title="$t('setup.dashboard.domain.cc1.header')" icon="fa-solid fa-globe fa-2x">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <router-link class="btn btn-default btn-block" :to="{ name: 'eSetup', query: {v: 'v-domains'}}">
                                {{$t('setup.dashboard.domain.cc1.btnLabel')}}
                            </router-link>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.domain.color" :small-title="lazyAICheckUI.domain.title" :icon="lazyAICheckUI.domain.icon" :showSeparator="false" :coloredSmallTitle="true">
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <div>
                    <stats-card type="default" :title="lazyAICheckUI.domain.vCount" :small-title="$t('setup.dashboard.domain.cc3.validDomain')" icon="fa-solid fa-globe">
                    </stats-card>
                </div>
                <div>
                    <stats-card type="default" :title="lazyAICheckUI.domain.hvOutTraining" :small-title="$t('setup.dashboard.domain.cc3.hvInTrainig')" icon="fa-solid fa-user-slash">
                    </stats-card>
                </div>
            </div>
        </div>

        <!-- Whitelisting -->
        <div class="row mt-3">
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.whitelisting.color" title="" :small-title="$t('setup.dashboard.whitelisting.cc1.header')" icon="fa-solid fa-filter fa-2x">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <router-link class="btn btn-default btn-block" :to="{ name: 'eSetup', query: {v: 'v-whitelisting'}}">
                                {{$t('setup.dashboard.whitelisting.cc1.btnLabel')}}
                            </router-link>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.whitelisting.color" :small-title="lazyAICheckUI.whitelisting.title" :icon="lazyAICheckUI.whitelisting.icon" :showSeparator="false" :coloredSmallTitle="true">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <p-button type="warning" block @click="markWhitelistingAsValid" v-if="lazyAICheckUI.whitelisting.showWhitelistingValidBtn">
                                {{$t('setup.dashboard.whitelisting.cc2.btnCustomValid')}}
                            </p-button>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <card>
                    <slot name="header">
                        <h5 class="text-muted">{{$t('setup.dashboard.whitelisting.cc3.header')}}</h5>
                    </slot>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-muted">{{$t('setup.dashboard.whitelisting.cc3.text')}}</p>
                        </div>
                        <div class="col-12 ml-2">
                            <p v-for="d in getDomains">
                                <span class="fa-solid fa-globe mr-2 text-muted"></span>
                                <b>{{d}}</b>
                            </p>
                        </div>
                    </div>
                </card>
            </div>
        </div>

        <!-- Customization -->
        <div class="row mt-3">
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.customization.color" title="" :small-title="$t('setup.dashboard.cbCustom.cc1.header')" icon="fa fa-sliders fa-2x">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <router-link class="btn btn-default btn-block" :to="{ name: 'eSetup', query: {v: 'v-customization'}}">
                                {{$t('setup.dashboard.cbCustom.cc1.btnLabel')}}
                            </router-link>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <control-card :type="lazyAICheckUI.customization.color" :small-title="lazyAICheckUI.customization.title" :icon="lazyAICheckUI.customization.icon" :showSeparator="false" :coloredSmallTitle="true">
                    <div class="row" slot="footer">
                        <div class="col-12 text-center">
                            <p-button type="warning" block @click="markCustomAsValid" v-if="lazyAICheckUI.customization.showCustomValidBtn">
                                {{$t('setup.dashboard.cbCustom.cc2.btnCustomValid')}}
                            </p-button>
                        </div>
                    </div>
                </control-card>
            </div>
            <div class="col-12 col-md-4">
                <card>
                    <slot name="header">
                        <h5 class="text-muted">{{$t('setup.dashboard.cbCustom.cc3.header')}}</h5>
                    </slot>
                    <div class="row lazyAI-customizable-table">
                        <div class="col-12 d-flex justify-content-between">
                            <span class="text-muted cust-label">{{$t('setup.dashboard.cbCustom.cc3.name')}}</span>
                            <span class="text-muted cust-value">{{customData.name}}</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="text-muted cust-label">{{$t('setup.dashboard.cbCustom.cc3.abbreviation')}}</span>
                            <span class="text-muted cust-value">{{customData.abbreviation}}</span>
                        </div>
                        <div class="col-12 d-flex justify-content-between">
                            <span class="text-muted cust-label">{{$t('setup.dashboard.cbCustom.cc3.color')}}</span>
                            <span class="text-muted cust-value">
                                <div :style="{'background-color': customData.color}" class="custom-data-color-icon"></div>
                                {{customData.color}}
                            </span>
                        </div>
                        <div class="col-12 text-center my-5 entity-customization">
                            <img class="logo-preview img-fluid" :src="customData.logoUrl" height="120" />
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Modal, Progress, ControlCard, StatsCard } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    export default {
        name: "setup-dashboard",
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            [Progress.name]: Progress,
            ControlCard,
            StatsCard,
        },
        props: {
            entityHID: {
                type: String,
                required: true
            }
        },
        data() {
            let vueThis = this;
            return {
                lazyAICheck: {},
            }
        },
        methods: {
            initComponent() {
                this.loadCheck();
            },
            loadCheck() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                //let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.lazyAIcheck));
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.setupCheckUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.lazyAICheck = response.data;
                        //loader.hide();
                        swal.close();
                    }).catch(function (error) {
                        swal.close();
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        //loader.hide();
                    });
            },
            markHvListAsValid() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.listHvIsValidUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.loadCheck();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            markCustomAsValid() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.customizationIsValidUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.loadCheck();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            markWhitelistingAsValid() {
                if (this.entityHID === undefined) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.whitelistingIsValidUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.loadCheck();
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
        },
        computed: {
            lazyAICheckUI() {
                let result = {
                    hv: {
                        color: 'success',
                        title: '',
                        icon: '',
                        lastUpdateUtc: '-',
                        hvCount: '0',
                        showHvListValidBtn: false,
                    },
                    domain: {
                        color: 'success',
                        title: '',
                        icon: '',
                        vCount: '0',
                        hvOutTraining: '0',
                    },
                    whitelisting: {
                        color: 'success',
                        showWhitelistingValidBtn: false,
                        title: '',
                        icon: '',
                    },
                    customization: {
                        color: 'success',
                        title: '',
                        icon: '',
                        showCustomValidBtn: false,
                    }
                };

                //--- HV
                if (this.lazyAICheck.hvCount != undefined) {
                    result.hv.hvCount = this.lazyAICheck.hvCount.toString();
                }

                if (this.lazyAICheck.lastHvListCheckUtc != null) {
                    result.hv.lastUpdateUtc = this.$moment(this.lazyAICheck.lastHvListCheckUtc).format("DD.MM.YYYY HH:mm");
                }

                if (this.lazyAICheck.lastHvListCheckUtc === null) {
                    result.hv.color = 'danger';
                    result.hv.title = this.$t('setup.dashboard.hv.cc2.uploadHvListLabel');
                    result.hv.icon = 'fa fa-exclamation fa-3x';
                }
                else {
                    let nowUtc = this.$moment();
                    let lastHvListCheckUtc = this.$moment(this.lazyAICheck.lastHvListCheckUtc);
                    let diff = nowUtc.diff(lastHvListCheckUtc, 'months');
                    if (diff < 6) {
                        result.hv.color = 'success';
                        result.hv.title = this.$t('setup.dashboard.hv.cc2.hvListLatest');
                        result.hv.icon = 'fa fa-check fa-3x';
                    }
                    else {
                        result.hv.color = 'warning';
                        result.hv.title = this.$t('setup.dashboard.hv.cc2.hvListToCheck');
                        result.hv.icon = 'fa fa-exclamation fa-3x';
                        result.hv.showHvListValidBtn = true;
                    }
                }
                if (this.lazyAICheck.isSubscriptionSizeExhausted) {
                    result.hv.color = 'danger';
                    result.hv.title = this.$t('setup.dashboard.hv.cc2.hvListSizeOver');
                    result.hv.icon = 'fa fa-exclamation fa-3x';
                }

                //-- Domain
                if (this.lazyAICheck.domainValidCount != undefined) {
                    result.domain.vCount = this.lazyAICheck.domainValidCount.toString() + "/" + (this.lazyAICheck.domainValidCount + this.lazyAICheck.domainUnvalidCount).toString();
                }
                result.domain.hvOutTraining = (this.lazyAICheck.hvCount - this.lazyAICheck.hvsInTrainingCount).toString();


                if (this.lazyAICheck.domainValidCount == 0) {
                    result.domain.color = 'danger';
                    result.domain.title = this.$t('setup.dashboard.domain.cc2.noValid');
                    result.domain.icon = 'fa fa-exclamation fa-3x';
                }
                else if (this.lazyAICheck.domainUnvalidCount == 0 && this.lazyAICheck.domainValidCount > 0) {
                    result.domain.color = 'success';
                    result.domain.title = this.$t('setup.dashboard.domain.cc2.allValid');
                    result.domain.icon = 'fa fa-check fa-3x';
                }
                else if (this.lazyAICheck.domainUnvalidCount > 0) {
                    result.domain.color = 'warning';
                    result.domain.title = this.$t('setup.dashboard.domain.cc2.anyNoValid');
                    result.domain.icon = 'fa fa-exclamation fa-3x';
                }

                //-- Whitelisting
                if (this.lazyAICheck.whitelistingIsValidUtc == undefined && this.lazyAICheck.whitelistingIsValidUtc == null) {
                    result.whitelisting.color = 'warning';
                    result.whitelisting.title = this.$t('setup.dashboard.whitelisting.cc2.checkWhitelisting');
                    result.whitelisting.icon = 'fa fa-exclamation fa-3x';
                    result.whitelisting.showWhitelistingValidBtn = true;
                }
                else {
                    result.whitelisting.color = 'success';
                    result.whitelisting.title = this.$t('setup.dashboard.whitelisting.cc2.whitelistingCompelete');
                    result.whitelisting.icon = 'fa fa-check fa-3x';
                }

                //-- Customization
                if (this.lazyAICheck.customizationIsValidUtc == undefined && this.lazyAICheck.customizationIsValidUtc == null) {
                    result.customization.color = 'warning';
                    result.customization.title = this.$t('setup.dashboard.cbCustom.cc2.checkCustomData');
                    result.customization.icon = 'fa fa-exclamation fa-3x';
                    result.customization.showCustomValidBtn = true;
                }
                else {
                    result.customization.color = 'success';
                    result.customization.title = this.$t('setup.dashboard.cbCustom.cc2.customDataApproved');
                    result.customization.icon = 'fa fa-check fa-3x';
                }

                return result;
            },
            customData() {
                if (this.lazyAICheck.customizationData == undefined) return {};
                return this.lazyAICheck.customizationData[0];
            },
            getDomains() {
                return this.$root.config.emailDomains;
            }
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    this.initComponent();
                }
            }
        },
    }
</script>

<style scoped>
</style>