<template>
    <div class="card" ref="content">
        <div class="card-header">
            <div class="row">
                <div class="col d-flex justify-content-start" >
                    <h4 class="card-title" data-v-step="step-name">{{$t('setup.domains.header')}}</h4>
                </div>
                <div class="col text-right">
                    <el-tooltip :content="$t('setup.domains.btnRefreshTooltip')" :open-delay="300" placement="top">
                        <p-button type="neutral" class="mr-2" icon @click="refresh()">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </p-button>
                    </el-tooltip>
                </div>
            </div>
            <p>{{$t('setup.domains.text')}}</p>
        </div>
        <div class="card-body">
            <!-- toolbar -->
            <div class="row toolbar">
                <div class="col-12">
                    <p-button type="success" @click="createDomain" data-v-step="step-new">
                        <i class="nc-icon nc-simple-add"></i> {{$t('setup.domains.toolsbar.btnNewD')}}
                    </p-button>
                    <p-button type="danger" @click="deleteDomains" :disabled="disableMassButtons" data-v-step="step-delete">
                        <i class="fa-solid fa-trash-can"></i> {{$t('setup.domains.toolsbar.btnDelD')}}
                    </p-button>
                    <p-button type="info" @click="showBlacklist" data-v-step="step-blacklist">
                        <i class="fa-solid fa-globe"></i> {{$t('setup.domains.toolsbar.btnBlacklist')}}
                    </p-button>
                </div>
            </div>
            <!-- header -->
            <div class="row justify-content-between">
                <div class="col-12 col-md-6 pt-0 pt-md-2">

                </div>
                <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                    <div class="d-flex justify-content-end">
                        <fg-input class="input-sm mr-2"
                                  :placeholder="$t('setup.domains.searchPlaceholder')"
                                  v-model="searchQuery"
                                  addon-right-icon="nc-icon nc-zoom-split">
                        </fg-input>
                        <el-select class="select-default float-sm-left page-size"
                                   v-model="pagination.perPage">
                            <el-option class="select-default"
                                       v-for="item in pagination.perPageOptions"
                                       :key="item"
                                       :label="item"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </div>
                </div>


                <div class="col-12 col-md-6 pt-0 pt-md-2">

                </div>
                <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                    <div class="d-flex justify-content-end">

                    </div>
                </div>

            </div>
            <!-- table -->
            <div class="row">
                <div class="col-12" ref="table">
                    <el-table :data="queriedData" :empty-text="$t('setup.domains.table.noData')"
                              :default-sort="{ prop: 'domainName', order: 'ascending'}"
                              @sort-change="handleSortChange" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" v-if="enableSelect">
                        </el-table-column>
                        <el-table-column prop="domainName" sortable="custom" :label="$t('setup.domains.table.colName')">
                        </el-table-column>
                        <el-table-column prop="testEmail" sortable="custom" :label="$t('setup.domains.table.colEmail')">
                        </el-table-column>
                        <el-table-column prop="isValid" sortable="custom" :label="$t('setup.domains.table.colState')" width="120" align="center">
                            <template slot-scope="props">
                                <el-tooltip content="Domain is validated" :open-delay="300" placement="left" v-if="props.row.isValid">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                </el-tooltip>
                                <el-tooltip content="Domain is not validated" :open-delay="300" placement="left" v-else>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>

                        <el-table-column :width="160" align="center"
                                         class-name="td-actions">
                            <template slot="header" slot-scope="scope">
                                <span data-v-step="step-actions">{{$t('setup.domains.table.colActions.header')}}</span>
                            </template>
                            <template slot-scope="props">
                                <el-tooltip :content="$t('setup.domains.table.colActions.btnEdit')" :open-delay="300" placement="top">
                                    <p-button type="default" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="props.row.isValid">
                                        <i class="fa-solid fa-envelope-circle-check"></i>
                                    </p-button>
                                </el-tooltip>
                                <el-tooltip :content="$t('setup.domains.table.colActions.btnResend')" :open-delay="300" placement="top">
                                    <p-button type="default" size="sm" icon @click="validateEmail(props.row.hid)" :disabled="props.row.isValid || !props.row.hasEmail">
                                        <i class="fa-solid fa-rotate-right" aria-hidden="true"></i>
                                    </p-button>
                                </el-tooltip>
                                <el-tooltip :content="$t('setup.domains.table.colActions.btnDelete')" :open-delay="300" placement="top">
                                    <p-button type="danger" size="sm" icon @click="handleDeleteDomain(props.row)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </p-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>

                </div>
            </div>
            <!-- paging -->
            <div class="row">
                <div class="col-sm-6 pagination-info">
                    <p class="category">{{$t('setup.domains.pagingInfo', [from + 1, to, total])}}</p>
                </div>
                <div class="col-sm-6">
                    <p-pagination class="pull-right"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  :total="pagination.total">
                    </p-pagination>
                </div>
            </div>
        </div>


        <!-- blacklist modal -->
        <domain-blacklist :show="modals.blacklistModal" :entityHID="entityHID"
                          @hide="blacklistHide">
        </domain-blacklist>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Modal, Collapse, CollapseItem, Tabs, TabPane, Card, Progress, Pagination } from 'src/components/UIComponents'
    import swal from 'sweetalert2'
    import blacklistModal from 'src/components/UIComponents/ModalDialog/DomainBlackList.vue'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: "domains",
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            Card,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            [blacklistModal.name]: blacklistModal,
        },
        props: {
            entityHID: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                domains: [],
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                propsToSearch: ['domainName','testEmail', 'isValid'],
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'domainName' },
                domain2Mass: [],
                enableSelect: true,
                modals: {
                    blacklistModal: false,
                },
            }
        },
        methods: {
            validateEmail: function (dhid) {
                let vueThis = this;
                swal.fire(this.$root.config.getSweetAlertCfg_Waiting);
                this.axios.post(this.$root.config.entity.domain.sendTestEmailUrl(this.entityHID), null, { params: { domainHID: dhid } })
                    .then(response => {
                        swal.fire({
                            icon: 'success',
                            html: vueThis.$t('setup.domains.testEmailDialog.success'),
                            confirmButtonClass: 'btn btn-success btn-fill',
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            initComponent: function () {
                this.loadDomains();
            },
            loadDomains() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.entity.domain.getDomainsUrl(this.entityHID), null)
                    .then(response => {
                        loader.hide();
                        this.domains = response.data;
                        this.domains.forEach(function (item) {
                            item.hasEmail = Boolean(item.testEmail);
                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            refresh() {
                this.loadDomains();
            },
            createDomain() {
                var vueThis = this;
                swal.fire({
                    title: vueThis.$t('setup.domains.createDomain.msg'),
                    input: 'text',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return vueThis.$t('setup.domains.createDomain.errReq');
                        }
                        if (value.trim().length > this.$root.config.maxDomainNameLength) {
                            return vueThis.$t('setup.domains.createDomain.errMax', [this.$root.config.maxDomainNameLength]);
                        }
                    }
                }).then(function (result) {
                    if (!result.value) return;
                    vueThis.axios.post(vueThis.$root.config.entity.domain.createDomainUrl(vueThis.entityHID), null, { params: { dName: result.value.trim() } })
                        .then(response => {
                            if (result == null || result == undefined) {
                                result = { value: "NaN" };
                            }
                            vueThis.loadDomains();
                            swal.fire({
                                icon: 'success',
                                html: vueThis.$t('setup.domains.createDomain.success', [result.value]),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });                            
                        })
                        .catch(function (error) {
                            if (result == null || result == undefined) {
                                result = { value: "NaN" };
                            }
                            if (error.response.status == 400) {
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('setup.domains.createDomain.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: result.value }),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },
            handleDeleteDomain(domain) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('setup.domains.deleteDomain.msg', [domain.domainName]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.entity.domain.deleteDomainsUrl(vueThis.entityHID), { ParentHID: vueThis.entityHID, HIDs: [domain.hid] })
                            .then(response => {
                                if (domain == null || domain == undefined) {
                                    domain = { domainName: "NaN" };
                                }
                                vueThis.loadDomains();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('setup.domains.deleteDomain.success', [domain.domainName]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (domain == null || domain == undefined) {
                                    domain = { domainName: "NaN" };
                                }
                                if (error.response.status == 400) {
                                    let respMsg = error.response.data;
                                    swal.fire({
                                        title: vueThis.$t('setup.domains.deleteDomain.failed'),
                                        text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg', { name: domain.domainName }),
                                        icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            handleSelectionChange(vals) {
                //console.log(vals);
                this.domain2Mass = vals;
            },
            deleteDomains() {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('setup.domains.deleteDomains.msg');
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.entity.domain.deleteDomainsUrl(vueThis.entityHID), { ParentHID: vueThis.entityHID, HIDs: vueThis.domain2Mass.map(c => c.hid) })
                            .then(response => {
                                vueThis.loadDomains();
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('setup.domains.deleteDomains.success'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    let respMsg = error.response.data;
                                    swal.fire({
                                        title: vueThis.$t('setup.domains.deleteDomains.failed'),
                                        text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                        icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                        showConfirmButton: true,
                                        timer: vueThis.$root.config.alertTimer15,
                                        timerProgressBar: true,
                                    });
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
            showBlacklist() {
                this.modals.blacklistModal = true;
            },
            blacklistHide() {
                this.modals.blacklistModal = false;
            },
            //----------
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            handleEdit(index, row) {
                //console.log('edit', index, row);
                let vueThis = this;
                swal.fire({
                    title: vueThis.$t('setup.domains.testEmailDialog.msg', [row.domainName]),
                    input: 'email',
                    inputPlaceholder: vueThis.$t('setup.domains.testEmailDialog.inputPlaceholder'),
                }).then(function (result) {
                    if (!result.isConfirmed) return;
                    swal.fire(vueThis.$root.config.getSweetAlertCfg_Waiting);
                    vueThis.axios.post(vueThis.$root.config.entity.domain.setTestEmailUrl(vueThis.entityHID), null, { params: { domainHID: row.hid, tEmail: result.value } })
                        .then(response => {
                            vueThis.loadDomains();
                            swal.fire({
                                icon: 'success',
                                html: vueThis.$t('setup.domains.testEmailDialog.success'),
                                confirmButtonClass: 'btn btn-success btn-fill',
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        })
                        .catch(function (error) {
                            if (error.response.status == 400) {
                                let respMsg = error.response.data;
                                swal.fire({
                                    title: vueThis.$t('setup.domains.testEmailDialog.failed'),
                                    text: vueThis.$t('apiErrorCode.' + respMsg.code + '.msg'),
                                    icon: vueThis.$t('apiErrorCode.' + respMsg.code + '.icon'),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }
                            else {
                                vueThis.writeEx2Log(error);
                                vueThis.genericErrorAlert();
                            }
                        });
                });
            },

        },
        mounted: function () {
            this.initComponent();
        },
        computed: {
            disableMassButtons() {
                return this.domain2Mass.length == 0;
            },
            //--------------------
            queriedData() {
                var cThis = this;
                if (!this.searchQuery) {
                    this.pagination.total = this.domains.length;
                    return this.domains.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }
                let result = this.domains
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.pagination.total;
            },
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    this.initComponent();
                }
            }
        },
    }
</script>

<style scoped>
</style>