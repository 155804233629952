<template>
    <div>
        <div class="row mb-3">
            <div class="col-12" v-if="viewBy != 'campaign'">
                <!-- header -->
                <div class="row justify-content-between">
                    <div class="col-12 col-md-6 pt-0 pt-md-2">

                    </div>
                    <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                        <div class="d-flex justify-content-end">
                            <fg-input class="input-sm  mr-2"
                                      :placeholder="$t('campaignBlock.cbDistribution.searchPlaceholder')"
                                      v-model="searchQuery"
                                      addon-right-icon="nc-icon nc-zoom-split">
                            </fg-input>
                            <el-select class="select-default float-sm-left page-size"
                                       v-model="pagination.perPage">
                                <el-option class="select-default"
                                           v-for="item in pagination.perPageOptions"
                                           :key="item"
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>

                </div>
            </div>
            <!-- table -->
            <div class="col-12">
                <el-table :data="queriedData"  :empty-text="$t('campaignBlock.cbDistribution.table.noData')"
                          :default-sort="{ prop: 'name', order: 'ascending'}"
                          @sort-change="handleSortChange">
                    <el-table-column :label="$t('campaignBlock.cbDistribution.table.colName')" property="name" sortable="custom">                        
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.name}} ({{row.cultureCode}})
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countInitiated" sortable="custom">
                        <span slot="header" class="text-initiated">
                            <i class="fa-solid fa-paper-plane" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countInitiated}}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countInteracted" sortable="custom">
                        <span slot="header" class="text-interacted">
                            <i class="fa-solid fa-envelope-open-text" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countInteracted}}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countReacted" sortable="custom">
                        <span slot="header" class="text-reacted">
                            <i class="fa-solid fa-hand-point-up" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countReacted}}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countCompromised" sortable="custom">
                        <span slot="header" class="text-compromised">
                            <i class="fa fa-bomb" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countCompromised}}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countReported" sortable="custom">
                        <span slot="header" class="text-reported">
                            <i class="fa fa-flag" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countReported}}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center" property="countTrained" sortable="custom">
                        <span slot="header" class="text-trained">
                            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                        </span>
                        <div slot-scope="{row}" class="td-number">
                            <div class="">
                                {{row.countTrained}}
                            </div>
                        </div>
                    </el-table-column>
                </el-table>
            </div>

            <!-- paging -->
            <div class="col-12" v-if="viewBy != 'campaign'">
                <div class="row">
                    <div class="col-sm-6 pagination-info">
                        <p class="category">{{$t('campaignBlock.cbDistribution.pagingInfo', [from + 1, to, total])}}</p>
                    </div>
                    <div class="col-sm-6">
                        <p-pagination class="pull-right"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total="pagination.total">
                        </p-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, TableColumn } from 'element-ui'
    import { Pagination } from 'src/components/UIComponents'


    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'cb-distribution',
        props: {
            data: Array,
            viewBy: {
                type: String,
                description: '[entity|group|hv|campaign]'
            },
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Pagination.name]: Pagination,
        },
        data() {
            let vueThis = this;
            return {
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'name' },
                colwidth: 90,
                propsToSearch: ['name'],
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
            }
        },
        methods: {

            //-------------------------------
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
        },
        computed: {
            //------------------------------
            queriedData() {
                if (this.data == undefined) return [];
                var cThis = this;
                if (!this.searchQuery) {
                    this.pagination.total = this.data.length;
                    return this.data.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }
                let result = this.data
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.pagination.total;
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>
</style>