<template>
    <div>
        <bar-chart :labels="chartXAxisLabel" ref="barChart"
                   :height="100"
                   :datasets="chartDataSets" :extraOptions="extraOptions">
        </bar-chart>
    </div>
</template>

<script>
    import BarChart from 'src/components/UIComponents/Charts/BarChart'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'weekday-distribution-chart',
        props: {
            chartData: Array,
        },
        components: {
            BarChart,
        },
        data() {
            let vueThis = this;
            return {
                extraOptions: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        //display: false,
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                            }
                        }]
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                    },
                },
            }
        },
        computed: {
            orderedChartData() {
                if (this.chartData == undefined) return undefined;
                if (this.chartData.length == 0) return undefined;
                let tmpData = this.chartData.map((x) => x);
                tmpData.find(x => x.dayOfWeek == 0).dayOfWeek = 7;
                return tmpData.sort(function (a, b) { return sortingHandler(a, b, { order: 'ascending', name: 'dayOfWeek' }); })
            },
            chartXAxisLabel() {
                return [
                    this.$t('charts.weekDayDistChart.monLabel'),
                    this.$t('charts.weekDayDistChart.tueLabel'),
                    this.$t('charts.weekDayDistChart.wedLabel'),
                    this.$t('charts.weekDayDistChart.thuLabel'),
                    this.$t('charts.weekDayDistChart.friLabel'),
                    this.$t('charts.weekDayDistChart.satLabel'),
                    this.$t('charts.weekDayDistChart.sunLabel')
                ];
            },
            chartInitiatedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countInitiated);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.initiatedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#6bd098',
                    borderColor: '#6bd098',
                };

            },
            chartInteractedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countInteracted);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.interactedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#fbc658',
                    borderColor: '#fbc658',
                };
            },
            chartReactedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countReacted);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.reactedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#ffa500',
                    borderColor: '#ffa500',
                };
            },
            chartCompromisedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countCompromised);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.compromisedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#ef8157',
                    borderColor: '#ef8157',
                };
            },
            chartTrainedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countTrained);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.trainedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#51bcda',
                    borderColor: '#51bcda',
                };
            },
            chartReportedData() {
                let dataValue = [];
                if (this.orderedChartData != undefined) {
                    for (let i = 0; i < this.orderedChartData.length; i++) {
                        dataValue.push(this.orderedChartData[i].countReported);
                    }
                }
                return {
                    label: this.$t('charts.weekDayDistChart.reportedLabel'),
                    fill: true,
                    borderWidth: 5,
                    data: dataValue,
                    backgroundColor: '#e2f6eb',
                    borderColor: '#e2f6eb',
                };
            },
            chartDataSets() {
                return [
                    this.chartInitiatedData,
                    this.chartInteractedData,
                    this.chartReactedData,
                    this.chartCompromisedData,
                    this.chartTrainedData,
                    this.chartReportedData,
                ];
            },
        }
    }
</script>

<style scoped>
</style>