<template>
    <div>
        <div class="card card-stats file-uploader" @click="uploadFile">
            <div class="card-body">
                <div class="row">
                    <div class="col-5 col-md-4">
                        <slot name="header">
                            <div class="icon-big text-center" v-bind:style="{color: color}">
                                <i :class="icon" v-bind:style="{ 'background-color': backgroundColor }"></i>
                            </div>
                        </slot>
                    </div>
                    <div class="col-7 col-md-8">
                        <slot name="content">
                            <div class="numbers">
                                <p class="card-category">{{smallTitle}}</p>
                                <p class="card-title">{{title}}</p>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <hr />
                <slot name="footer"></slot>
            </div>
        </div>
        <input type="file" @change="handleFileChange" style="display: none;" ref="fileinput" accept=".csv,.txt" />
    </div>
</template>
<script>
    import swal from 'sweetalert2'

    export default {
        name: 'upload-card',
        props: {
            color: {
                type: String,
                description: 'icon color'
            },
            backgroundColor: {
                type: String,
                description: 'icon background color'
            },
            title: {
                type: String,
                description: 'Card title'
            },
            smallTitle: {
                type: String,
                description: 'Card small title'
            },
            icon: {
                type: String,
                description: 'Card icon'
            },
            url: {
                type: String,
                description: 'URL of server for upload file'
            },
            hvGroupID: {
                type: Number,
                description: 'GroupID for uploading users'
            },
            entityID: {
                type: Number,
                description: 'EntityID for uploading users'
            },
            mode: {
                type: String,
                default: 'toGroup',
                description: 'toEntity|toGroup'
            }
        },
        data() {
            return {
                csvStruct: {
                    toEntity: 'no header and order of columns<br/>email, first name, last name, group name <br/> and separator ";"',
                    toGroup: 'no header and order of columns<br/>email, first name, last name <br/> and separator ";"',
                }
            }
        },
        methods: {
            handleFileChange(e) {
                let csvfile = e.target.files[0];
                let formData = new FormData();
                formData.append('entityId', this.entityID);
                if (this.mode === 'toGroup')
                    formData.append('groupId', this.hvGroupID);
                formData.append('mode', this.mode);
                formData.append('dataFile', csvfile);
                let vueThis = this;
                this.axios.post(this.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(response => {
                        if (response.data.type == 1) {
                            vueThis.$emit('uploadFinished', true);
                        }
                        else {
                            vueThis.$emit('uploadFinished', false);
                        }
                    }).catch(function (ex) {
                        vueThis.writeEx2Log(ex);
                        vueThis.$emit('uploadFinished', false);
                    });
            },
            uploadFile: function () {
                let msg = this.csvStruct.toGroup;
                if (this.mode === 'toEntity')
                    msg = this.csvStruct.toEntity;
                swal.fire({
                    icon: 'info',
                    title: 'Structure of CSV file',
                    html: msg,
                    onClose: () => {

                    }
                }).then(() => {
                    this.$refs.fileinput.click();
                });
            },

        }
    }

</script>
<style scoped>
    .card-content {
        min-height: 85px
    }

    .icon-big >>> i {
        border-radius: 50%;
    }

    .icon-big >>> i:before {
        padding: 10px;
    }

</style>
